import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import functions from './utils/functions'
import Login from './pages/auth/Login'
import Clogin from './pages/authClient/LoginClient'
import Home from './pages/dashboard/Home'
import Forgot from './pages/auth/Forgot'
import NotFound from './pages/error/NotFound'
import Payments from './pages/finance/Payments'
import Reset from './pages/auth/Reset'
// import Settings from './pages/customer/Settings'
// import Preferences from './pages/customer/Preferences'
// import Notifications from './pages/customer/Notifications'
// import Password from './pages/customer/Password'
// import DeleteAccount from './pages/customer/DeleteAccount'
// import AllCustomers from './pages/customer/AllCustomers'
import Users from './pages/users/Users'
import Clients from './pages/clients/Clients'
import AccountSettings from './pages/AccountSettings'
import AddClient from './pages/clients/AddClient'
import AddUser from './pages/users/AddUser'
import Statement from './pages/finance/Statement'
import HomeClient from './pages/dashboard/HomeClient'
import Management from './pages/Management'
import UserSettings from './pages/UserSettings'
import Reports from './pages/reports/Reports'
import GeneralReports from './pages/reports/GeneralReports'
import LoginClient from './pages/authClient/LoginClient'
import ResetClient from './pages/authClient/ResetClient'
import Transfers from './pages/clients/Transfers'
import PendingPayments from './pages/finance/PendingPayments'
import FailedPayments from './pages/finance/FailedPayments'
import SuccessPayments from './pages/finance/SuccessPayments'
import TransfersAll from './pages/clients/TransfersAll'
import ManageAccount from './pages/ManageAccount'
import PaymentLinks from './pages/clients/PaymentLinks'


class App extends Component {


  state = {
    screen: "Login",
    loggedIn: true,
    accessInfo: functions.permissionGuard()
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    if (!localStorage.getItem("paybunny@user")) {
      this.setState({
        loggedIn: false
      })
    } else {
      this.setState({
        loggedIn: true
      })
    }
  }
  render() {
    const roleList = functions.roleGuard()
    const USER_BOUNDARY = functions.userBoundary();
    return (
      // <AuthContext>
      <Router basename="/" forceRefresh={true}>

        <Switch>

          {!this.state.loggedIn && <Route exact path="/" component={Login} />}
          {!this.state.loggedIn && <Route exact path="/login" component={Login} />}
          {!this.state.loggedIn && <Route exact path="/client/login" component={LoginClient} />}
          {/* {this.state.loggedIn && <Route exact path="/reset-password-client" component={ResetClient} />} */}

          {roleList == "SUPER" &&
            <>
              <Route exact path="/home" component={Home} />
              <Route exact path="/" component={Home} />
              <Route exact path="/customer-transactions" component={Payments} />
              <Route exact path="/reset-password" component={Reset} />

              {/* <Route exact path='/settings' component={Settings} /> */}
              {/* <Route exact path='/preferences' component={Preferences} /> */}
              {/* <Route exact path='/notification-settings' component={Notifications} /> */}
              {/* <Route exact path='/change-client-password' component={Password} /> */}
              {/* <Route exact path='/delete-client-account' component={DeleteAccount} /> */}

              <Route exact path='/all-transactions' component={Payments} />
              <Route exact path='/clients-all' component={Clients} />
              <Route exact path="/manage-users" component={Users} />
              <Route exact path="/manage-clients" component={Clients} />
              <Route exact path="/account-settings" component={AccountSettings} />
              <Route exact path="/new-client" component={AddClient} />
              <Route exact path="/new-user" component={AddUser} />
              <Route exact path="/client-details/:client" component={AccountSettings} />
              <Route exact path="/account-statement" component={Statement} />
              {/* <Route exact path="/account-managment" component={Management} /> */}
              <Route exact path="/account-managment" component={ManageAccount} />
              <Route exact path="/user-details/:user" component={UserSettings} />
              <Route exact path="/payment-links" component={PaymentLinks} />
              <Route exact path="/system-reports" component={Reports} />
              <Route exact path="/custome-system-reports" component={GeneralReports} />
              <Route exact path="/transfer-out" component={Transfers} />

            </>
          }

          {roleList == "CLIENT" &&
            <>
            {/*  */}
              <Route exact path="/client/home" component={HomeClient} />
              <Route exact path="/customer-transactions" component={Payments} />
              {/* <Route exact path="/reset-password" component={Reset} /> */}
              <Route exact path="/reset-password-client" component={ResetClient} />
              <Route exact path='/all-transactions' component={Payments} />
              <Route exact path='/pending-transactions' component={PendingPayments} />
              <Route exact path='/failed-transactions' component={FailedPayments} />
              <Route exact path='/success-transactions' component={SuccessPayments} />
              <Route exact path="/account-settings" component={AccountSettings} />
              <Route exact path="/client-details/:client" component={AccountSettings} />
              <Route exact path="/account-statement" component={Statement} />
              {/* <Route exact path="/account-managment" component={Management} /> */}
              {/* <Route exact path="/account-managment" component={ManageAccount} /> */}
              <Route exact path="/system-reports" component={Reports} />
              <Route exact path="/user-details/:user" component={UserSettings} />
              <Route exact path="/transfer-out" component={Transfers} />
              <Route exact path="/payment-links" component={PaymentLinks} />
              <Route exact path="/transfers-all" component={TransfersAll} />
              <Route exact path="/custome-system-reports" component={GeneralReports} />
            </>
          }

          {/* <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={Forgot} />
          <Route exact path="*" component={NotFound} />
          <Route exact path="/login-client" component={Clogin} /> */}

        </Switch>
      </Router>
      // </AuthContext>
    )
  }
}
export default App