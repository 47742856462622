import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import ajax from '../utils/ajax'
import functions from '../utils/functions'

class AddClient extends Component {

    state = {
        username: '',
        password: '',
        type: '',
        info: '',
        load: '',
    }

    // onPressLogin = async (event) => {

    //     event.preventDefault()
    //     const { username, password } = this.state

    //     if (username.length > 0 && password.length > 0) {

    //         this.setState({
    //             type: '',
    //             info: '',
    //             // load: <Loader />
    //         })
    //         //fetch login from the API
    //         const server_response = await ajax.loginUser(username, password)

    //         if (server_response.status === 'OK') {
    //             localStorage.setItem(
    //                 'paybunny@user',
    //                 server_response.details.content.access_token,
    //             )

    //             // decode the token to access some variables for use
    //             const access_token = localStorage.getItem('paybunny@user')
    //             const decorded_token = functions.parseJwt(access_token)

    //             const role_id = decorded_token['data']['role_id']
    //             const is_secure = decorded_token['data']['is_secure']

    //             if (role_id === '2' || role_id === '3' || role_id === '4') {
    //                 this.setState({
    //                     type: 'alert alert-danger',
    //                     info: 'You are not authorised to use this system',
    //                     load: '',
    //                 })
    //             } else if (is_secure === '0') {
    //                 this.props.history.push('/reset-password')
    //                 window.location.reload()
    //             } else if (server_response.status === 'OK') {

    //                 this.setState({
    //                     type: 'alert alert-success',
    //                     info: 'Credentials Accepted, Redirecting you . . ',
    //                     load: '',
    //                 })

    //                 this.props.history.push('/')
    //                 window.location.reload()
    //             }
    //         } else {
    //             this.setState({
    //                 type: 'alert alert-danger',
    //                 info: server_response.details.message,
    //                 load: '',
    //             })
    //         }
    //     } else {
    //         this.setState({
    //             type: 'alert alert-danger',
    //             info: 'Username and Password Required',
    //             load: '',
    //         })
    //     }


    // }

    // onChangeUsername = (event) => {
    //     this.setState({ username: event.target.value })
    // }

    // onChangePassword = (event) => {
    //     this.setState({ password: event.target.value })
    // }

    render() {
        return (
            <div class="modal fade" id="Vertically">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Add Client</h6><button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    </div>

                    <div class="modal-body">
                        <form class="form-horizontal">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" id="inputName" placeholder="Full Name" />
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" id="inputName" placeholder="Username" />								</div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" id="inputName" placeholder="Phone Number" />
                                 </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control" id="inputName" placeholder="Email" />	
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <input type="text" class="form-control" id="inputName" placeholder="Address" />
                                 </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button class="btn ripple btn-primary" type="button">Add</button>
                        <button class="btn ripple btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
export default AddClient