import React, { Component } from 'react'
import { ThreeDots } from 'react-loader-spinner'


class DataLoader extends Component {
    render() {
        return (


            <div style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
            }}>

                <ThreeDots
                    height="80"
                    width="70"
                    radius="9"
                    color="#95d582"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}

                />

            </div>



        )
    }
}

export default DataLoader






