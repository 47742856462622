import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'

class ResetClient extends Component {



    state = {
        userRole: functions.roleGuard(),
        confirmPassword: '',
        password: '',
        type: '',
        info: '',
        //load: '',
    }

    onPressReset = async (event) => {
        event.preventDefault();
        const { password, confirmPassword } = this.state;

        if (
            password.length > 0 &&
            confirmPassword.length > 0
        ) {

            if (password !== confirmPassword) {
                this.setState({
                    type: 'alert alert-danger',
                    info: "Passwords Don't match",
                    //load: '',
                })
            } else {

                this.setState({
                    type: '',
                    info: '',
                    //load: <Loader />
                })

                const server_response = await ajax.resetPasswordClient(password);
                if (server_response.status === "OK") {
                    localStorage.removeItem('paybunny@user');
                    window.location.replace('/client/login');

                } else {
                    this.setState({
                        type: 'alert alert-danger',
                        info: server_response.details.message,
                        //load: '',
                    })
                }
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Both password fields Required',
                //load: '',
            })
        }


    }


    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }

    onConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value });
    }

    render() {
        return (

            <body class="ltr error-page1 bg-primary">
    
            {/* <!-- GLOBAL-LOADER --> */}
            {/* <div id="global-loader">
                <img src="https://php.spruko.com/nowa/nowa/assets/img/loader.svg" class="loader-img" alt="Loader"/>
            </div> */}
            {/* <!-- /GLOBAL-LOADER --> */}
    
            <div class="cover-image">
                <div class="page" >
                    
                    <div class="page-single">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main py-4 justify-content-center mx-auto">
                                    <div class="card-sigin">
                                         {/* <!-- Demo content--> */}
                                         <div class="main-card-signin d-md-flex">
                                             <div class="wd-100p">
                                                 <div class="d-flex mb-3"><a href="index.html"><img src="https://php.spruko.com/nowa/nowa/assets/img/brand/favicon.png" class="sign-favicon ht-40" alt="logo"/></a></div>
                                                 <div class="  mb-1">
                                                      <div class="main-signin-header">
                                                           <div class="">
                                                             <h2>Welcome please,</h2>

                                                             {this.state.showMsg}
                                                                <Notification
                                                                    message={{
                                                                        type: this.state.type,
                                                                        info: this.state.info,
                                                                    }}
                                                                />

                                                             <h4 class="text-start">Reset Your Password</h4>
                                                             <form method="post" onSubmit={this.onPressReset}>
                                                                 <div class="form-group text-start">
                                                                     <label>New Password</label>
                                                                     <input class="form-control" placeholder="Enter your password" type="password" onChange={this.onChangePassword} />
                                                                 </div>
                                                                 <div class="form-group text-start">
                                                                     <label>Confirm Password</label>
                                                                     <input class="form-control" placeholder="Enter your password" type="password" onChange={this.onConfirmPassword} />
                                                                 </div>
                                                                 {/* <a class="btn btn-primary btn-block" role="button" href="index.html">Reset Password</a> */}
                                                                 <button class="btn btn-lg btn-block btn-primary w-100" type="submit">Reset Password</button>
                                                             </form>
                                                          </div>
                                                      </div>
                                                      <div class="main-signup-footer mg-t-20 text-center">
                                                     <p>Already have an account? <a href="/login">Sign In</a></p>
                                                    </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
        )
    }

}
export default ResetClient