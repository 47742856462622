import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'
import Header from '../../common/Header'
import SideNav from '../../common/SideNav'
import Footer from '../../common/Footer'
import AdminStats from './components/AdminStats'
import ClientStats from './components/ClientStats'
import AdminRecent from './components/AdminRecent'
import ClientRecent from './components/ClientRecent'

class Home extends Component {

    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        username: '',
        gross: "00",
        actual: "00",
        expected: "00",
        missing: "00",
        principle: "00",
        failed: "00",
        netted: "00",
        transactionList: false
    }

    componentDidMount() {
        this.getUser();
        this.getGross();
        this.getPrinciple();
        this.getFailed();
        this.getNet();
        this.getRecentTransactions();
        this.getCurrentBalance();
    }

    

    getUser = async () => {
        const server_response = await ajax.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                username: server_response.details.content.username,
                roleName: server_response.details.content.user_role.role_name,
                photo: server_response.details.content.profile_photo.file_path

            })
            console.log("#############", server_response.details.content)
        } else {
            // console.log(server_response)
        }
    }

    getGross = async () => {
        const server_response = await ajax.getGross();
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                gross: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getPrinciple = async () => {
        const server_response = await ajax.getPrinciple();
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                principle: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getFailed = async () => {
        const server_response = await ajax.getFailed();
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                failed: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getNet = async () => {
        const server_response = await ajax.getNet();
        if (server_response.status === "OK") {
            // console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                netted: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getRecentTransactions = async () => {
        const server_response = await ajax.listRecentTransactions();

        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content.list)
            this.setState({
                transactionList: server_response.details.content.list
            })

        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }

    getCurrentBalance = async () => {
        const server_response = await ajax.accountBalance();
        if (server_response.status === "OK") {

            this.setState({
                actual: server_response.details.content.true_balance.amount_c,
                expected: server_response.details.content.apparent_balance.amount_c,
                missing: server_response.details.content.missing_balance.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    render() {
        const transactionLists = this.state.transactionList
        const userRoles = functions.roleGuard()
        return (
            <>
                <body class="ltr main-body app sidebar-mini">

                    <div class="page">

                        {<Header />}
                        {<SideNav />}

                        {/* <!-- MAIN-CONTENT --> */}
                        <div class="main-content app-content">

                            {/* <!-- container --> */}
                            <div class="main-container container-fluid">

                                {/* <!-- breadcrumb --> */}
                                <div class="breadcrumb-header justify-content-between">
                                        
                                </div>
                                {/* <!-- /breadcrumb --> */}

                                {/* <!-- row --> */}

                                {userRoles == "SUPER" &&
                                    <AdminStats />
                                }
                                {userRoles == "CLIENT" &&
                                    <ClientStats />
                                }

                                {/* <!-- row closed --> */}

                                {/* <!-- row  --> */}
                                {userRoles == "SUPER" &&
                                    <AdminRecent />
                                }

                                {userRoles == "CLIENT" &&
                                    <ClientRecent />
                                }

                                {/* <!-- /row --> */}

                            </div>
                            {/* <!-- /Container --> */}
                        </div>
                        {/* <!-- MAIN-CONTENT CLOSED --> */}

                        {<Footer />}

                    </div>
                    {/* <!-- End Page --> */}

                    {/* <!-- BACK-TO-TOP --> */}
                    <a href="#top" id="back-to-top"><i class="las la-arrow-up"></i></a>


                </body>
            </>
        )
    }
}
export default Home