import React, { Component } from 'react'
import { Modal, Select, Table } from 'antd'
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from './Notification'
import functions from '../utils/functions'
import ajax from '../utils/ajax'

const { Option } = Select;

class ReceiveMoney extends Component {
    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        mobileTransfer: false,
        amountHolder: false,
        bankTransfer: false,
        showMsg: false,
        dialog: false,
        payType: "",
        showMsg: false
    }

    handleCancel = () => {
        const sent = this.props
        this.setState({ visible: false })
        sent.handleCancel(false)
    }

    onChangePhone = (event) => {

        if (event != null) {
            this.setState({
                clientuser: event.target.value
            })
        }

    }

    onChangeAmount = (event) => {

        if (event != null) {
            this.setState({
                amountToSend: event.target.value
            })
        }

    }

    onPressProccess = async (event) => {

        event.preventDefault()
        const { clientuser, amountToSend } = this.state

        if (clientuser.length > 0 && amountToSend.length > 0) {

            this.setState({
                load: true,
                showMsg: false
            })

            //process transfer from the API
            const server_response = await ajax.transferIn(this.state.userId, amountToSend, clientuser)

            if (server_response.status === 'OK') {

                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: false,
                    showMsg: true
                })

            } else {
                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: false,
                    showMsg: true
                })
            }
        } else {
            // this.setState({
            //     type: 'alert alert-danger',
            //     info: 'Username and Password Required',
            //     load: false,
            // })
        }


    }

    render() {
        return (

            <Modal
                title={"Receive Money / Cash-In"}
                centered
                bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
                visible={this.props.visible.visible}
                maskClosable={false}
                footer={[null]}
                maskTransitionName=""
                onCancel={this.handleCancel}
                width={500}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: false }}
            >

                {this.state.showMsg ? (
                    <>
                        <div style={{ height: "20px" }}>
                            <Notification
                                message={{
                                    type: this.state.type,
                                    info: this.state.info,
                                }}
                            />
                        </div>
                        <br /><br />
                    </>) : null}

                <form class="form-horizontal" method="post" onSubmit={this.onPressProccess}>

                    <div class="modal-body">
                        <div class="row">

                            <div class="form-group col-md-12">
                                <label for="validationDefaultUsername" class="form-label">Payer Phone Number</label>
                                <div class="input-group">
                                    {/* <span class="input-group-text" id="inputGroupPrepend2">@</span> */}
                                    <input type="text" onChange={this.onChangePhone} class="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" required />
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="validationDefaultUsername" class="form-label">Amount</label>
                                <div class="input-group">
                                    <input type="text" onChange={this.onChangeAmount} class="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" required />
                                </div>

                            </div>

                        </div>
                        {/* </form> */}

                        <button class="btn ripple btn-secondary" disabled={this.state.load} type="submit">

                            {this.state.load ? (<>
                                <span style={{ margin: '0 20px' }} class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Transfering funds please wait ...
                            </>
                            ) : (<>Process Transfer</>)}

                        </button>

                    </div>
                </form>

            </Modal>

        )
    }
}

export default ReceiveMoney