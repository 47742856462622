import React, { Component } from 'react'
import Header from '../../common/Header'
import SideNav from '../../common/SideNav'
import Footer from '../../common/Footer'
import AddClient from '../../common/AddClient'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'
import { Link } from 'react-router-dom'
import DataLoader from '../../common/DataLoader'

class Clients extends Component {

    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        load: false,
        clientList: false,
        loading: true
    };

    componentDidMount() {
        this.getAllClients();
    }

    onChangeUsername = (event) => {
        this.setState({ load: true })
    }

    // onClickRegister = () => {
    //   this.setState({ dialog: true })
    // }

    // recievedState = (data) => {
    //   this.setState({ dialog: data })
    // }

    getAllClients = async () => {
        const server_response = await ajax.listClients();
        const tableData = []

        if (server_response.status === "OK") {
            // console.log("kakakakakakakakakkakakakakakk ",server_response.details.content.list)
            this.setState({
                loading: false,
                clientList: server_response.details.content.list
            })

        } else {
            this.setState({
                loading: false,
                clientList: "404"
            })
        }
    }

    render() {
        const newuserlist = this.state.clientList;

        return (
            <body class="ltr main-body app sidebar-mini">

                {/* <!-- GLOBAL-LOADER --> */}
                {/* <div id="global-loader">
                    <img src="https://php.spruko.com/nowa/nowa/assets/img/loader.svg" class="loader-img" alt="Loader" />
                </div> */}
                {/* <!-- /GLOBAL-LOADER --> */}

                {/* <!-- Page --> */}
                <div class="page">

                    {<Header />}
                    {<SideNav />}

                    {/* <!-- MAIN-CONTENT --> */}
                    <div class="main-content app-content">

                        {/* <!-- container --> */}


                        <div class="main-container container-fluid">

                            {/* <!-- breadcrumb --> */}


                            <div class="breadcrumb-header justify-content-between">
                                <div class="left-content mt-2">
                                    <Link to="new-client" class="btn ripple btn-primary" href="#"><i class="fe fe-plus me-2"></i>Add New Client</Link>
                                </div>

                            </div>

                            <div class="row row-sm">
                                <div class="col-lg-12">
                                    {this.state.loading ? (<DataLoader />) :
                                        <>

                                            <div class="card custom-card">
                                                <div class="card-body">
                                                    <div class="table-responsive  deleted-table">
                                                        <table id="user-datatable" class="table  table-bordered text-nowrap border-bottomxs">
                                                            <thead>
                                                                <tr>
                                                                    <th class="wd-2">
                                                                        Photo
                                                                    </th>
                                                                    <th>Full Name</th>
                                                                    <th>Phone</th>
                                                                    <th>Email</th>
                                                                    <th>Address</th>
                                                                    <th>Last Active</th>
                                                                    <th>Verification</th>
                                                                    <th>Created At</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {newuserlist && newuserlist !== "404" &&
                                                                    newuserlist.map((listValue, index) =>

                                                                        <tr>
                                                                            <td class="text-center">
                                                                                <div class="avatar-sm  rounded-circle">
                                                                                    <img alt="avatar" class="rounded-circle" src="https://php.spruko.com/nowa/nowa/assets/img/faces/4.jpg" />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={
                                                                                    {
                                                                                        pathname: `/client-details/${listValue.client_id}`,
                                                                                        state: {
                                                                                            client: listValue.client_id,
                                                                                            fullname: listValue.full_name,
                                                                                            phone: listValue.phone_number,
                                                                                            email: listValue.email,
                                                                                            address: listValue.address,
                                                                                            username: listValue.username
                                                                                        }
                                                                                    }}>
                                                                                    <span class="tx-14 font-weight-semibold text-dark mb-1">{listValue.full_name}</span>
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <span class="text-dark tx-13">{listValue.phone_number}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span class="badge bg-light text-dark tx-13">{listValue.email}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span class="text-dark tx-13">{listValue.address}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span class="text-dark tx-13">{listValue.last_seen ? (listValue.last_seen) : "Never"} </span>
                                                                            </td>
                                                                            <td>
                                                                                {listValue.status === "1" ? (<span class="badge font-weight-semibold bg-success-transparent text-success tx-11">Verified</span>) : <span class="badge font-weight-semibold bg-danger-transparent text-danger tx-11">Not Verified</span>}
                                                                            </td>
                                                                            <td>
                                                                                <span class="text-dark tx-13">{listValue.created_at.short_date}</span>
                                                                            </td>

                                                                            <td>
                                                                                <a href="javascript:void(0);" data-bs-target="#Vertically" data-bs-toggle="modal" class="btn btn-icon btn-danger-light me-2" title="" data-bs-original-title="Edit">
                                                                                    <i class="las la-pause"></i></a>
                                                                            </td>
                                                                        </tr>

                                                                    )}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }

                                </div>
                            </div>


                            {/* <!-- End Row --> */}
                            {/* <!-- row closed  --> */}
                        </div>
                        {/* <!-- Container closed --> */}

                        <div class="modal fade" id="Vertically">
                            <div class="modal-dialog modal-md modal-dialog-centered" role="document">
                                <div class="modal-content modal-content-demo">
                                    <div class="modal-header" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <h6 class="modal-title">Suspend / Activate Client </h6><button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                                    </div>

                                    <div class="modal-body">
                                        <form class="form-horizontal">
                                            <div class="row">
                                                <div class="form-group col-md-12">

                                                    {/* <div class="card custom-card card-body">
                                                        <p class="card-text">
                                                            Client will be suspended an will no longer have access to the system.
                                                        </p>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn ripple btn-secondary" data-bs-dismiss="modal" type="button">Request Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- New User Modal--> */}

                    {this.state.load ? (
                        <div class="modal fade" id="Vertically">
                            {<AddClient />}
                        </div>
                    ) : null}

                    {/* <!-- /New User Modal--> */}
                    {/* <!-- MAIN-CONTENT CLOSED --> */}

                    {/* <!--APP-SIDEBAR-RIGHT--> */}
                    <div class="sidebar sidebar-right sidebar-animate">
                        <div class="panel panel-primary card mb-0 box-shadow">
                            <div class="tab-menu-heading card-img-top-1 border-0 p-3">
                                <div class="card-title mb-0">Notifications</div>
                                <div class="card-options ms-auto">
                                    <a href="javascript:void(0);" class="sidebar-remove"><i class="fe fe-x"></i></a>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body latest-tasks p-0 border-0">
                                <div class="tabs-menu ">
                                    {/* <!-- Tabs --> */}
                                    <ul class="nav panel-tabs">
                                        <li class=""><a href="#side1" class="active" data-bs-toggle="tab">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg> */}
                                            Chat</a></li>
                                        <li><a href="#side2" data-bs-toggle="tab">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"/></svg> */}
                                            Notifications</a></li>
                                        <li><a href="#side3" data-bs-toggle="tab">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="side-menu__icon"  height="24" version="1.1" width="24"  viewBox="0 0 24 24"><path d="M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11C11.17,11 10.5,10.33 10.5,9.5C10.5,8.67 11.17,8 12,8C12.83,8 13.5,8.67 13.5,9.5C13.5,10.33 12.83,11 12,11Z" /></svg> */}
                                            Friends</a></li>
                                    </ul>
                                </div>
                                <div class="tab-content">
                                    <div class="tab-pane active " id="side1">
                                        <div class="list d-flex align-items-center border-bottom p-3">
                                            <div class="">
                                                <span class="avatar bg-primary brround avatar-md">CH</span>
                                            </div>
                                            <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                <p class="mb-0 d-flex ">
                                                    <b>New Websites is Created</b>
                                                </p>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex align-items-center">
                                                        <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                        <small class="text-muted ms-auto">30 mins ago</small>
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="list d-flex align-items-center border-bottom p-3">
                                            <div class="">
                                                <span class="avatar bg-danger brround avatar-md">N</span>
                                            </div>
                                            <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                <p class="mb-0 d-flex ">
                                                    <b>Prepare For the Next Project</b>
                                                </p>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex align-items-center">
                                                        <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                        <small class="text-muted ms-auto">2 hours ago</small>
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="tab-pane  " id="side2">
                                        <div class="list-group list-group-flush ">

                                            <div class="list-group-item d-flex  align-items-center">
                                                <div class="me-3">
                                                    <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"><span class="avatar-status bg-success"></span></span>
                                                </div>
                                                <div>
                                                    <strong>Olivia</strong> Hey! there I' am available....
                                                    <div class="small text-muted">
                                                        12 mintues ago
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane  " id="side3">
                                        <div class="list-group list-group-flush ">

                                            <div class="list-group-item d-flex  align-items-center">
                                                <div class="me-2">
                                                    <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/7.jpg"></span>
                                                </div>
                                                <div class="">
                                                    <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
                                                </div>
                                                <div class="ms-auto">
                                                    <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!--/APP-SIDEBAR-RIGHT--> */}

                    {<Footer />}

                </div>
                {/* <!-- End Page --> */}

                {/* <!-- BACK-TO-TOP --> */}
                <a href="#top" id="back-to-top"><i class="las la-arrow-up"></i></a>

            </body>
        )
    }
}
export default Clients