import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Notification from '../../common/Notification'
//import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'

class Login extends Component {

    state = {
        username: '',
        password: '',
        type: '',
        info: '',
        load: false,
        showMsg: false
    }

    onPressLogin = async (event) => {

        event.preventDefault()
        const { username, password } = this.state

        if (username.length > 0 && password.length > 0) {

            this.setState({
                type: '',
                info: '',
                load: true,
                showMsg: false
            })
            //fetch login from the API
            const server_response = await ajax.loginUser(username, password)

            if (server_response.status === 'OK') {
                localStorage.setItem(
                    'paybunny@user',
                    server_response.details.content.access_token,
                )

                // decode the token to access some variables for use
                const access_token = localStorage.getItem('paybunny@user')
                const decorded_token = functions.parseJwt(access_token)

                const role_id = decorded_token['data']['role_id']
                const is_secure = decorded_token['data']['is_secure']

                // if (role_id === '2' || role_id === '3' || role_id === '4') {
                //     this.setState({
                //         type: 'alert alert-danger',
                //         info: 'You are not authorised to use this system',
                //         load: false,
                //         showMsg: true
                //     })
                // } else if
                if (is_secure === '0') {
                    this.props.history.push('/reset-password')
                    window.location.reload()
                } else if (server_response.status === 'OK') {

                    this.setState({
                        type: 'alert alert-success',
                        info: 'Credentials Accepted, Redirecting you . . ',
                        load: false,
                        showMsg: true
                    })

                    this.props.history.push('/')
                    window.location.reload()
                }
            } else {
                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: false,
                    showMsg: true
                })
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Username and Password Required',
                load: false,
            })
        }


    }

    onChangeUsername = (event) => {
        this.setState({ username: event.target.value })
    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value })
    }

    render() {
        return (



            <body class="ltr error-page1 bg-primary">

                {/* <!-- GLOBAL-LOADER --> */}
                {/* <div id="global-loader">
                    <img src="https://php.spruko.com/nowa/nowa/assets/img/loader.svg" class="loader-img" alt="Loader" />
                </div> */}
                {/* <!-- /GLOBAL-LOADER --> */}

                <div class="square-box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="page" >
                    <div class="row">
                        <div class="d-flex">
                            <a class="demo-icon new nav-link" href="javascript:void(0);">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" class="header-icon-svgs fa-spin" width="24" height="24" viewBox="0 0 24 24"><path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path><path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path></svg> */}
                            </a>
                        </div>
                    </div>
                    <div class="page-single">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-4 justify-content-center">
                                    <div class="card-sigin">
                                        {/* <!-- Demo content--> */}
                                        <div class="main-card-signin d-md-flex">
                                            <div class="wd-100p">
                                                <div class="d-flex mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <a href="/">
                                                        <img src="assets/img/brand/logo.png" class="sign-favicon ht-40" alt="logo" />
                                                    </a>
                                                </div>
                                                <div class="">
                                                    <div class="main-signup-header">
                                                        <div class="panel panel-primary">

                                                            <div class="panel-body tabs-menu-body border-0 p-3">

                                                                {this.state.showMsg}
                                                                <Notification
                                                                    message={{
                                                                        type: this.state.type,
                                                                        info: this.state.info,
                                                                    }}
                                                                />

                                                                <h6 style={{ display: 'flex', justifyContent: 'center' }} class="font-weight-semibold mb-4">Please sign in to continue.</h6>
                                                                <div class="tab-content">
                                                                    <div class="tab-pane active" id="tab5">
                                                                        <form method="post" onSubmit={this.onPressLogin}>
                                                                            <div class="form-group">
                                                                                <label>Username</label> <input class="form-control" placeholder="Enter your email" onChange={this.onChangeUsername} required="" type="text" />
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Password</label> <input class="form-control" placeholder="Enter your password" onChange={this.onChangePassword} required="" type="password" />
                                                                            </div>
                                                                            {/* <a class="btn btn-primary btn-block" role="button" href="index.html">Sign In</a> */}
                                                                            {/* <button type="submit" class="btn btn-lg btn-block btn-primary w-100">Login</button> */}
                                                                            <button type="submit" class="btn btn-lg btn-block btn-primary w-100" disabled={this.state.load} >
                                                                                {this.state.load ? (<>
                                                                                    <span style={{ margin: '0 20px' }} class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                    Loging you in ...
                                                                                </>
                                                                                ) : (<>Login</>)}

                                                                            </button>
                                                                        </form>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="main-signin-footer text-center mt-3">
                                                            <p><a href="/forgot-password" class="mb-3">Forgot password?</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </body>

        )
    }
}
export default Login