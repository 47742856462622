import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ajax from '../../utils/ajax'
import { toUpper } from 'lodash'
import functions from '../../utils/functions'
import DataLoader from '../../common/DataLoader'

class ClientTransfers extends Component {

    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        transactionList: false,
        transactionListOld: false,
        loading: true,
        currentPage: 1,
        metaData: false,
    }

    componentDidMount() {
        this.getRecentTransactions();
    }

    getRecentTransactions = async () => {
        const server_response = await ajax.listTransfersClient(this.state.userId, this.state.currentPage);

        if (server_response.status === "OK") {

            //console.log("jakaaaa ", server_response.details.content.list)

            this.setState({
                transactionList: server_response.details.content.list,
                transactionListOld: server_response.details.content.list,
                metaData: server_response.details.content.meta,
                loading: false
            })

        } else {
            this.setState({
                transactionList: "404",
                metaData: false,
                loading: false
            })
        }
    }

    onClickNext = () => {
        const { currentPage, metaData } = this.state
        if (currentPage < metaData.total_pages) {
            this.setState({
                currentPage: currentPage + 1
            }, () => this.fetchMore(currentPage + 1))

        }
    }

    filterTransactions = (event) => {

        const stat = this.state
        const currentValue = toUpper(event.target.value)
        this.setState({ searchWord: currentValue })
        const filteredData = stat.transactionListOld.filter(
            (entry) =>
                toUpper(entry.payer.full_name).includes(currentValue) ||
                toUpper(entry.payee).includes(currentValue) ||
                toUpper(entry.amount.total_c).includes(currentValue) ||
                toUpper(entry.fees.total_c).includes(currentValue) ||
                toUpper(entry.internalRef).includes(currentValue) ||
                toUpper(entry.externalRef).includes(currentValue)
            ,

        )
        this.setState({ transactionList: filteredData })

    }

    fetchMore = async (currentPage) => {
        const { transactionList } = this.state

        const server_response = await ajax.listRecentTransfersClient(currentPage);

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            this.setState({
                transactionList: transactionList.concat(incoming),
                metaData: server_response.details.content.meta
            })
        }

    }

    render() {
        const transactionLists = this.state.transactionList
        return (
            <>

                {/* <!-- row  --> */}
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <div class="card">

                            {this.state.loading ? (<DataLoader />) :
                                <>


                                    <div class="card-header">
                                        <h4 class="card-title">All Transfers</h4>
                                        <input style={{ display: 'flex', justifyContent: 'right' }} onChange={this.filterTransactions} class="form-control col-4 col-sm-4" />

                                        <div class="breadcrumb-header justify-content-between">
                                        </div>

                                    </div>

                                    <div class="card-body pt-0">
                                        <div class="table-responsive">
                                            <table class="table  table-bordered text-nowrap mb-0"> {/* id="example1" add filters to the table */}
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th>PAYER NAME</th>
                                                        <th>BENEFICIARY</th>
                                                        <th>AMOUNT</th>
                                                        <th>FEES </th>
                                                        <th>INTERNAL REF</th>
                                                        <th>EXTERNAL REF</th>
                                                        <th>STATUS</th>
                                                        <th>CREATED AT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {transactionLists && transactionLists !== "404" &&
                                                        transactionLists.map((listValue, index) =>

                                                            <tr>
                                                                <td class="text-center">{index + 1}</td>
                                                                <td>{listValue.payer.full_name}</td>
                                                                <td>{listValue.payee}</td>
                                                                <td>{listValue.amount.total_c}</td>
                                                                <td>{listValue.fees.total_c}</td>
                                                                <td>{listValue.internalRef}</td>
                                                                <td>{listValue.externalRef}</td>
                                                                <td>

                                                                    {(listValue.status === "3" ? <span class="badge badge-success">Success</span> : (listValue.transaction_status === "2" ? (<span class="badge badge-danger">Failed</span>) : (<span class="badge badge-orange">Pending</span>)))}

                                                                </td>
                                                                <td>{listValue.created_at.long_date}</td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>

                                            {/* pagination */}
                                            {this.state.metaData.current_page != null &&
                                                <div class="text-wrap">
                                                    <div class="example" style={{ height: '60px', paddingTop: '10px' }}>
                                                        <ul class="pagination" style={{ display: 'flex', justifyContent: 'right' }}>
                                                            <li class="page-item">
                                                                <button class="btn btn-outline-success mx-2 button-icon" disabled data-bs-toggle="button">&nbsp; &nbsp; {this.state.metaData.current_page + "  /  " + this.state.metaData.total_pages}&nbsp; &nbsp; </button>
                                                            </li>

                                                            <li class="page-item">
                                                                <a class="page-link" onClick={this.onClickNext}>
                                                                    <i class="icon ion-ios-arrow-forward"></i>
                                                                    <i class="icon ion-ios-arrow-forward"></i>
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                            {/* pagination */}

                                        </div>
                                    </div>


                                </>
                            }



                        </div>
                    </div>
                </div>
                {/* <!-- /row --> */}

            </>
        )
    }
}
export default ClientTransfers