import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ajax from '../../../utils/ajax'
import functions from '../../../utils/functions'
import DataLoader from '../../../common/DataLoader'

class AdminRecent extends Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        loading: true
    }

    componentDidMount() {
        this.getRecentTransactions();
    }

    getRecentTransactions = async () => {
        const server_response = await ajax.listRecentTransactions();


        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content.list)
            this.setState({
                loading: false,
                transactionList: server_response.details.content.list
            })

        } else {
            this.setState({
                loading: false,
                transactionList: "404"
            })
        }
    }

    render() {
        const transactionLists = this.state.transactionList
        return (
            <>

                {/* <!-- row  --> */}
                <div class="row">

                    <div class="col-12 col-sm-12">
                        <div class="card">

                            {this.state.loading ? (<DataLoader />) :
                                <>
                                    <div class="card-header">
                                        <h4 class="card-title">Most recent transactions</h4>
                                        <div class="breadcrumb-header justify-content-between">
                                            <div class="justify-content-center mt-2">
                                                <Link to="/all-transactions">
                                                    <button type="button" class="btn btn-primary">
                                                        <i class="fe fe-list me-1"></i> View All
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            <div class="card-body pt-0">
                                <div class="table-responsive">
                                    {this.state.loading ? (
                                        null
                                    ) : (<>

                                        <table class="table  table-bordered text-nowrap mb-0"> {/* id="example1" add filters to the table */}
                                            <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th>CLIENT NAME</th>
                                                    <th>MSISDN</th>
                                                    <th>AMOUNT</th>
                                                    <th>FEE</th>
                                                    <th>TYPE</th>
                                                    <th>INTERNAL REF</th>
                                                    <th>EXTERNAL REF</th>
                                                    <th>STATUS</th>
                                                    <th>CREATED AT</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {transactionLists && transactionLists !== "404" &&
                                                    transactionLists.map((listValue, index) =>

                                                        <tr>
                                                            <td class="text-center">{index + 1}</td>
                                                            <td>{listValue.transaction_client.full_name}</td>
                                                            <td>{listValue.transaction_payee}</td>
                                                            <td>{listValue.transaction_amount.total_c}</td>
                                                            <td>{listValue.transaction_amount.total_c}</td>
                                                            <td>{listValue.transaction_type === "INBOUND" ? <strong class="text-success">INBOUND</strong> : listValue.transaction_type === "OUTBOUND" ? <strong class="text-warning">OUTBOUND</strong> : ""}</td>
                                                            <td>{listValue.transaction_internal_ref}</td>
                                                            <td>{listValue.transaction_external_ref}</td>
                                                            <td>

                                                                {(listValue.transaction_status === "3" ? <span class="badge badge-success">Success</span> : (listValue.transaction_status === "2" ? (<span class="badge badge-danger">Failed</span>) : (<span class="badge badge-orange">Pending</span>)))}

                                                            </td>
                                                            <td>{listValue.transaction_created_at.long_date}</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>

                                    </>)}



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /row --> */}

            </>
        )
    }
}
export default AdminRecent