import React, { Component } from 'react'
import ajax from '../../../utils/ajax'
import functions from '../../../utils/functions'

class ClientStats extends Component {

    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        username: '',
        gross: "00",
        actual: "00",
        expected: "00",
        missing: "00",
        principle: "00",
        failed: "00",
        netted: "00",
        debit:"00"
    }

    componentDidMount() {
        if(this.state.userRole == "CLIENT"){
            this.getClient();
        }else{
        this.getUser();
        }
        this.getGross();
        this.getPrinciple();
        this.getFailed();
        this.getNet();
        this.getCurrentBalance();
        this.getSuccessTranfer();
    }

    getSuccessTranfer = async () => {
        const server_response = await ajax.listSuccessfulPayouts(this.state.userId);
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                debit: server_response.details.content.amount_c
            })
        } else {
            // console.log(server_response)
        }
    }

    getClient = async () => {
        const server_response = await ajax.clientInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                username: server_response.details.content.username,
                roleName: server_response.details.content.user_role.role_name,
                photo: server_response.details.content.profile_photo.file_path

            })
            //console.log("#############", server_response.details.content)
        } else {
            // console.log(server_response)
        }
    }
    
    getUser = async () => {
        const server_response = await ajax.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                username: server_response.details.content.username,
                roleName: server_response.details.content.user_role.role_name,
                photo: server_response.details.content.profile_photo.file_path

            })
        } else {
            // console.log(server_response)
        }
    }

    getGross = async () => {
        const server_response = await ajax.getGross(this.state.userId);
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                gross: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getPrinciple = async () => {
        const server_response = await ajax.getPrinciple(this.state.userId);
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                principle: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getFailed = async () => {
        const server_response = await ajax.getFailed(this.state.userId);
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                failed: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }



    getNet = async () => {
        const server_response = await ajax.getCurrentBal(this.state.userId);
        if (server_response.status === "OK") {
            // console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                netted: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getCurrentBalance = async () => {
        const server_response = await ajax.accountBalance(this.state.userId);
        if (server_response.status === "OK") {

            this.setState({
                actual: server_response.details.content.true_balance.amount_c,
                expected: server_response.details.content.apparent_balance.amount_c,
                missing: server_response.details.content.missing_balance.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    // getTotalDebit = async () => { //
        
    //     const server_response = await ajax.getDebit(this.state.userId);
    //     if (server_response.status === "OK") {
            
    //         this.setState({
    //             debit: server_response.details.content.amount_c

    //         })
    //     } else {
    //         // console.log(server_response)
    //     }
    // }
    render() {

        return (

            <>

                {/* <!-- row --> */}
                <div class="row">
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        <div class="card primary-custom-card1">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                                        <div class="prime-card"><img class="img-fluid" src="https://php.spruko.com/nowa/nowa/assets/img/png/dashboard8.png" style={{ height: '190px' }} alt="" /></div>
                                    </div>
                                    <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12">
                                        <div class="text-justified align-items-center">
                                            <h2 class="text-dark font-weight-semibold mb-3 mt-2">Hi, Welcome Back <span class="text-primary">{this.state.username}!</span></h2>
                                            {/* <p class="text-dark tx-17 mb-2 lh-3"> You have used the 10% of free plan storage. Upgrade your plan to get unlimited storage.</p> */}
                                            <p class="font-weight-semibold tx-12 mb-4">For billing related queries, contact us through support mail us at support@paybunny.com </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        {/* <!-- <div class="container"> --> */}
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image1">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12 ">Total Credit</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-20 font-weight-semibold mb-2">$ {this.state.principle}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-shopping-bag tx-16 text-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image2">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12">Total Debit</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-20 font-weight-semibold mb-2">$ {this.state.debit}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-shopping-bag tx-16 text-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image3">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12">Current Balance</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-20 font-weight-semibold mb-2">$ {this.state.netted}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-shopping-bag tx-16 text-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image3">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12">Total Failed</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-20 font-weight-semibold mb-2">$ {this.state.failed}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-shopping-bag tx-16 text-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- </div> --> */}
                </div>
                {/* <!-- row closed --> */}


            </>
        )
    }
}
export default ClientStats