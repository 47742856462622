import React, { Component } from 'react'
import { Table, Tooltip, Progress } from 'antd'
//import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import { toUpper } from 'lodash'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'
import Header from '../../common/Header'
import SideNav from '../../common/SideNav'
import Footer from '../../common/Footer'
import StatementView from './components/StatementView'
import StatementViewAdmin from './components/StatementViewAdmin'

class Statement extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        currentPage: 1,
        metaData: false,
        password: '',
        type: '',
        info: '',
        load: '',
        transactionList: false
    }

    componentDidMount() {

    }


    render() {
        const userRoles = functions.roleGuard()
        return (
            <>

                <body class="ltr main-body app sidebar-mini">

                    {/* <!-- GLOBAL-LOADER --> */}
                    {/* <div id="global-loader">
                        <img src="https://php.spruko.com/nowa/nowa/assets/img/loader.svg" class="loader-img" alt="Loader" />
                    </div> */}
                    {/* <!-- /GLOBAL-LOADER --> */}

                    {/* <!-- Page --> */}
                    <div class="page">

                        {<Header />}
                        {<SideNav />}

                        {/* <!-- MAIN-CONTENT --> */}
                        <div class="main-content app-content">

                            {/* <!-- container --> */}
                            <div class="main-container container-fluid">

                                {/* <!-- breadcrumb --> */}
                                <div class="breadcrumb-header justify-content-between">

                                </div>
                                {/* <!-- /breadcrumb --> */}

                                {/* <!--Row--> */}
                                {/* <!-- Row --> */}

                                {userRoles == "CLIENT" &&
                                    <StatementView user={userRoles == "CLIENT" ? this.state.userId : null} />
                                }

                                {userRoles == "SUPER" &&
                                    <StatementViewAdmin user={userRoles == "CLIENT" ? this.state.userId : null} />
                                }

                                {/* <!-- End Row --> */}
                                {/* <!-- row closed  --> */}
                            </div>
                            {/* <!-- Container closed --> */}
                        </div>

                        {/* <!-- MAIN-CONTENT CLOSED --> */}

                        {/* <!--APP-SIDEBAR-RIGHT--> */}
                        <div class="sidebar sidebar-right sidebar-animate">
                            <div class="panel panel-primary card mb-0 box-shadow">
                                <div class="tab-menu-heading card-img-top-1 border-0 p-3">
                                    <div class="card-title mb-0">Notifications</div>
                                    <div class="card-options ms-auto">
                                        <a href="javascript:void(0);" class="sidebar-remove"><i class="fe fe-x"></i></a>
                                    </div>
                                </div>
                                <div class="panel-body tabs-menu-body latest-tasks p-0 border-0">
                                    <div class="tabs-menu ">
                                        {/* <!-- Tabs --> */}
                                        <ul class="nav panel-tabs">
                                            <li class=""><a href="#side1" class="active" data-bs-toggle="tab">
                                                {/* <svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg> */}
                                                Chat</a></li>
                                            <li><a href="#side2" data-bs-toggle="tab">
                                                {/* <svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"/></svg> */}
                                                Notifications</a></li>
                                            <li><a href="#side3" data-bs-toggle="tab">
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="side-menu__icon"  height="24" version="1.1" width="24"  viewBox="0 0 24 24"><path d="M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11C11.17,11 10.5,10.33 10.5,9.5C10.5,8.67 11.17,8 12,8C12.83,8 13.5,8.67 13.5,9.5C13.5,10.33 12.83,11 12,11Z" /></svg> */}
                                                Friends</a></li>
                                        </ul>
                                    </div>
                                    <div class="tab-content">
                                        <div class="tab-pane active " id="side1">
                                            <div class="list d-flex align-items-center border-bottom p-3">
                                                <div class="">
                                                    <span class="avatar bg-primary brround avatar-md">CH</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>New Websites is Created</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">30 mins ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="list d-flex align-items-center border-bottom p-3">
                                                <div class="">
                                                    <span class="avatar bg-danger brround avatar-md">N</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>Prepare For the Next Project</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">2 hours ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="list d-flex align-items-center border-bottom p-3">
                                                <div class="">
                                                    <span class="avatar bg-info brround avatar-md">S</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>Decide the live Discussion</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">3 hours ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="list d-flex align-items-center border-bottom p-3">
                                                <div class="">
                                                    <span class="avatar bg-warning brround avatar-md">K</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>Meeting at 3:00 pm</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">4 hours ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="list d-flex align-items-center border-bottom p-3">
                                                <div class="">
                                                    <span class="avatar bg-success brround avatar-md">R</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>Prepare for Presentation</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">1 days ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="list d-flex align-items-center border-bottom p-3">
                                                <div class="">
                                                    <span class="avatar bg-pink brround avatar-md">MS</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>Prepare for Presentation</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">1 days ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="list d-flex align-items-center border-bottom p-3">
                                                <div class="">
                                                    <span class="avatar bg-purple brround avatar-md">L</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>Prepare for Presentation</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">45 mintues ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="list d-flex align-items-center p-3">
                                                <div class="">
                                                    <span class="avatar bg-secondary brround avatar-md">U</span>
                                                </div>
                                                <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                    <p class="mb-0 d-flex ">
                                                        <b>Prepare for Presentation</b>
                                                    </p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                            <small class="text-muted ms-auto">2 days ago</small>
                                                            <p class="mb-0"></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="tab-pane  " id="side2">
                                            <div class="list-group list-group-flush ">
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-3">
                                                        <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/12.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div>
                                                        <strong>Madeleine</strong> Hey! there I' am available....
                                                        <div class="small text-muted">
                                                            3 hours ago
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-3">
                                                        <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/1.jpg"></span>
                                                    </div>
                                                    <div>
                                                        <strong>Anthony</strong> New product Launching...
                                                        <div class="small text-muted">
                                                            5 hour ago
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-3">
                                                        <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/2.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div>
                                                        <strong>Olivia</strong> New Schedule Realease......
                                                        <div class="small text-muted">
                                                            45 mintues ago
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-3">
                                                        <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/8.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div>
                                                        <strong>Madeleine</strong> Hey! there I' am available....
                                                        <div class="small text-muted">
                                                            3 hours ago
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-3">
                                                        <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/11.jpg"></span>
                                                    </div>
                                                    <div>
                                                        <strong>Anthony</strong> New product Launching...
                                                        <div class="small text-muted">
                                                            5 hour ago
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-3">
                                                        <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/6.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div>
                                                        <strong>Olivia</strong> New Schedule Realease......
                                                        <div class="small text-muted">
                                                            45 mintues ago
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-3">
                                                        <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div>
                                                        <strong>Olivia</strong> Hey! there I' am available....
                                                        <div class="small text-muted">
                                                            12 mintues ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane  " id="side3">
                                            <div class="list-group list-group-flush ">
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Mozelle Belt</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/11.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Florinda Carasco</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel" ><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/10.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/2.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Zula Mclaughin</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/13.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Isidro Heide</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/12.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Mozelle Belt</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/4.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Florinda Carasco</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/7.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/2.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Zula Mclaughin</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel" ><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/14.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Isidro Heide</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/11.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Florinda Carasco</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/15.jpg"><span class="avatar-status bg-success"></span></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Zula Mclaughin</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex  align-items-center">
                                                    <div class="me-2">
                                                        <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/4.jpg"></span>
                                                    </div>
                                                    <div class="">
                                                        <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Isidro Heide</div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!--/APP-SIDEBAR-RIGHT--> */}

                        {<Footer />}

                    </div>
                    {/* <!-- End Page --> */}

                    {/* <!-- BACK-TO-TOP --> */}
                    <a href="#top" id="back-to-top"><i class="las la-arrow-up"></i></a>

                </body>
            </>
        )
    }
}
export default Statement