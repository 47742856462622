import React, { Component } from 'react'
import { Table, Tooltip, Progress } from 'antd'
//import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import ajax from '../../../utils/ajax'
import { toUpper } from 'lodash'
import functions from '../../../utils/functions'
import DataLoader from '../../../common/DataLoader'

class ClientPayments extends Component {

    state = {
        username: '',
        currentPage: 1,
        metaData: false,
        password: '',
        type: '',
        info: '',
        load: '',
        loading: true,
        transactionList: false,
        transactionListOld: false
    }

    componentDidMount() {
        this.getRecentTransactions();
    }

    getRecentTransactions = async () => {
        const server_response = await ajax.listRecentTransactionsAll(this.state.currentPage, this.props.user, this.props.type);

        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content.list)
            this.setState({
                loading: false,
                transactionList: server_response.details.content.list,
                transactionListOld: server_response.details.content.list,
                metaData: server_response.details.content.meta
            })

        } else {
            this.setState({
                loading: false,
                transactionList: "404",
                metaData: false
            })
        }
    }

    onClickNext = () => {
        const { currentPage, metaData } = this.state
        if (currentPage < metaData.total_pages) {
            this.setState({
                currentPage: currentPage + 1
            }, () => this.fetchMore(currentPage + 1))

        }
    }

    filterTransactions = (event) => {

        const stat = this.state
        const currentValue = toUpper(event.target.value)
        this.setState({ searchWord: currentValue })
        const filteredData = stat.transactionListOld.filter(
            (entry) =>
                toUpper(entry.transaction_client.full_name).includes(currentValue) ||
                toUpper(entry.transaction_payee).includes(currentValue) ||
                toUpper(entry.transaction_amount.total_c).includes(currentValue) ||
                toUpper(entry.transaction_internal_ref).includes(currentValue) ||
                toUpper(entry.transaction_external_ref).includes(currentValue) ||
                toUpper(entry.transaction_type).includes(currentValue)
            ,
        )
        this.setState({ transactionList: filteredData })

    }

    fetchMore = async (currentPage) => {
        const { transactionList } = this.state
        // this.setState({
        //     loading: true
        // })

        //restoreTable(detectTable());

        const server_response = await ajax.listRecentTransactionsAll(currentPage);
        // this.setState({
        //     loading: false
        // })

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            this.setState({
                transactionList: transactionList.concat(incoming),
                metaData: server_response.details.content.meta
            })
        }

    }

    render() {
        const transactionLists = this.state.transactionList
        const prop = this.props
        return (
            <>
                {/* <!-- Row --> */}
                <div class="row">
                    <div class="col-12 col-sm-12" >






                        <div class="card">
                            {this.state.loading ? (<DataLoader />) :
                                <>
                                    <div class="card-header">
                                        <h4 class="card-title">{this.props.type === "1" ? 'Pending Transactions' : this.props.type === "2" ? 'Failed Transactions' : this.props.type === "3" ? 'Successful Transactions' : 'All transactions'} </h4>
                                        <input style={{ display: 'flex', justifyContent: 'right' }} onChange={this.filterTransactions} class="form-control col-4 col-sm-4" />
                                    </div>


                                    <div class="card-body pt-0">
                                        <div class="table-responsive">
                                            <table class="table  table-bordered text-nowrap mb-0 "> {/* id="example1" add filters to the table */}
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th>CLIENT NAME</th>
                                                        <th>MSISDN</th>
                                                        <th>AMOUNT</th>
                                                        <th>TYPE</th>
                                                        <th>INTERNAL REF</th>
                                                        <th>EXTERNAL REF</th>
                                                        <th>STATUS</th>
                                                        <th>CREATED AT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {transactionLists && transactionLists !== "404" &&
                                                        transactionLists.map((listValue, index) =>

                                                            <tr>
                                                                <td class="text-center">{index + 1}</td>
                                                                <td>{listValue.transaction_client.full_name}</td>
                                                                <td>{listValue.transaction_payee}</td>
                                                                <td>{listValue.transaction_amount.total_c}</td>
                                                                <td>{listValue.transaction_type === "INBOUND" ? <strong class="text-success">INBOUND</strong> : listValue.transaction_type === "OUTBOUND" ? <strong class="text-warning">OUTBOUND</strong> : ''}</td>
                                                                <td>{listValue.transaction_internal_ref}</td>
                                                                <td>{listValue.transaction_external_ref}</td>
                                                                <td>

                                                                    {(listValue.transaction_status === "3" ? <span class="badge badge-success">Success</span> : (listValue.transaction_status === "2" ? (<span class="badge badge-danger">Failed</span>) : (<span class="badge badge-orange">Pending</span>)))}

                                                                </td>
                                                                <td>{listValue.transaction_created_at.long_date}</td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>


                                            {/* pagination */}
                                            {this.state.metaData.current_page != null &&
                                                <div class="text-wrap">
                                                    <div class="example" style={{ height: '60px', paddingTop: '10px' }}>
                                                        <ul class="pagination" style={{ display: 'flex', justifyContent: 'right' }}>
                                                            <li class="page-item">
                                                                <button class="btn btn-outline-success mx-2 button-icon" disabled data-bs-toggle="button">&nbsp; &nbsp; {this.state.metaData.current_page + "  /  " + this.state.metaData.total_pages}&nbsp; &nbsp; </button>
                                                            </li>

                                                            <li class="page-item">
                                                                <a class="page-link" onClick={this.onClickNext}>
                                                                    <i class="icon ion-ios-arrow-forward"></i>
                                                                    <i class="icon ion-ios-arrow-forward"></i>
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                            {/* pagination */}

                                        </div>
                                    </div>


                                </>
                            }
                        </div>
                    </div>
                </div>
                {/* <!-- End Row --> */}

            </>
        )
    }
}
export default ClientPayments