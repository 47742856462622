import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'

class Forgot extends Component {

    state = {
        username: '',
        password: '',
        type: '',
        info: '',
        load: '',
    }

    // onPressLogin = async (event) => {

    //     event.preventDefault()
    //     const { username, password } = this.state

    //     if (username.length > 0 && password.length > 0) {

    //         this.setState({
    //             type: '',
    //             info: '',
    //             // load: <Loader />
    //         })
    //         //fetch login from the API
    //         const server_response = await ajax.loginUser(username, password)

    //         if (server_response.status === 'OK') {
    //             localStorage.setItem(
    //                 'paybunny@user',
    //                 server_response.details.content.access_token,
    //             )

    //             // decode the token to access some variables for use
    //             const access_token = localStorage.getItem('paybunny@user')
    //             const decorded_token = functions.parseJwt(access_token)

    //             const role_id = decorded_token['data']['role_id']
    //             const is_secure = decorded_token['data']['is_secure']

    //             if (role_id === '2' || role_id === '3' || role_id === '4') {
    //                 this.setState({
    //                     type: 'alert alert-danger',
    //                     info: 'You are not authorised to use this system',
    //                     load: '',
    //                 })
    //             } else if (is_secure === '0') {
    //                 this.props.history.push('/reset-password')
    //                 window.location.reload()
    //             } else if (server_response.status === 'OK') {

    //                 this.setState({
    //                     type: 'alert alert-success',
    //                     info: 'Credentials Accepted, Redirecting you . . ',
    //                     load: '',
    //                 })

    //                 this.props.history.push('/')
    //                 window.location.reload()
    //             }
    //         } else {
    //             this.setState({
    //                 type: 'alert alert-danger',
    //                 info: server_response.details.message,
    //                 load: '',
    //             })
    //         }
    //     } else {
    //         this.setState({
    //             type: 'alert alert-danger',
    //             info: 'Username and Password Required',
    //             load: '',
    //         })
    //     }


    // }

    // onChangeUsername = (event) => {
    //     this.setState({ username: event.target.value })
    // }

    // onChangePassword = (event) => {
    //     this.setState({ password: event.target.value })
    // }

    render() {
        return (
            <body class="ltr error-page1 bg-primary">
    
            {/* <!-- GLOBAL-LOADER --> */}
            {/* <div id="global-loader">
                <img src="https://php.spruko.com/nowa/nowa/assets/img/loader.svg" class="loader-img" alt="Loader"/>
            </div> */}
            {/* <!-- /GLOBAL-LOADER --> */}
    
            <div class="cover-image">
                <div class="page" >
                    <div class="row">
                    
                    </div>
                    <div class="page-single">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main py-4 justify-content-center mx-auto">
                                    <div class="card-sigin">
                                        {/* <!-- Demo content--> */}
                                        <div class="main-card-signin d-md-flex">
                                            <div class="wd-100p">
                                                <div class="mb-3 d-flex"> <a href="index.html"><img src="https://php.spruko.com/nowa/nowa/assets/img/brand/favicon.png" class="sign-favicon ht-40" alt="logo"/></a></div>
                                                    <div class="main-card-signin d-md-flex bg-white">
                                                        <div class="wd-100p">
                                                            <div class="main-signin-header">
                                                                <h2>Forgot Password!</h2>
                                                                <h4>Please Enter Your Email</h4>
                                                                <form action="#">
                                                                    <div class="form-group">
                                                                         <label>Email</label> <input class="form-control" placeholder="Enter your email" type="text"/>
                                                                    </div>
                                                                    <a class="btn btn-primary btn-block" role="button" href="index.html">Send</a>
                                                                </form>
                                                            </div>
                                                            <div class="main-signup-footer mg-t-20 text-center">
                                                                <p>Forget it, <a href="/login"> Send me back</a> to the sign in screen.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
        )
    }
}
export default Forgot