import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../common/Footer'
import Header from '../common/Header'
import SideNav from '../common/SideNav'
import Notification from '../common/Notification'
import ajax from '../utils/ajax'
import functions from '../utils/functions'

class ManageAccount extends Component {
    state = {
        userId: functions.sessionGuard(),
        load: false,
        clientFullname: "",
        clientAddress: "",
        clientEmail: "",
        clientPhone: "",
        clientUsername: ""
    };

    componentDidMount() {
    }

    onChangeUsername = (event) => {
        this.setState({ clientUsername: event.target.value })
    }

    onChangePhone = (event) => {
        this.setState({ clientPhone: event.target.value })
    }

    onChangeFullname = (event) => {
        this.setState({ clientFullname: event.target.value })
    }

    onChangeEmail = (event) => {
        this.setState({ clientEmail: event.target.value })
    }

    onChangeAddress = (event) => {
        this.setState({ clientAddress: event.target.value })
    }

    onPressCreateClient = async (event) => {
        event.preventDefault()
        const {
            clientFullname,
            clientAddress,
            clientEmail,
            clientPhone,
            clientUsername
        } = this.state

        if (clientFullname.length > 0 && clientAddress.length > 0 && clientEmail.length > 0 && clientPhone.length > 0 && clientUsername.length > 0) {

            this.setState({
                type: '',
                info: '',
                load: true,
                showMsg: false
            })

            const server_response = await ajax.createClient(clientFullname, clientAddress, clientEmail, clientPhone, clientUsername)

            if (server_response.status === 'OK') {
                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: false,
                    showMsg: true,

                    clientFullname: "",
                    clientAddress: "",
                    clientEmail: "",
                    clientPhone: "",
                    clientUsername: ""

                })

            } else {
                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: false,
                    showMsg: true
                })
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'All client information is required',
                load: false,
            })
        }
    }

    render() {
        return (
            <body class="ltr main-body app sidebar-mini">

                <div class="page">

                    {<Header />}
                    {<SideNav />}

                    {/* <!-- MAIN-CONTENT --> */}
                    <div class="main-content app-content">

                        {/* <!-- container --> */}
                        <div class="main-container container-fluid">

                            {/* <!-- breadcrumb --> */}
                            <div class="breadcrumb-header justify-content-between">
                                <div class="left-content">
                                    <span class="main-content-title mg-b-0 mg-b-lg-1">SETTINGS</span>
                                </div>
                                <div class="justify-content-center mt-2">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item active" aria-current="page">Settings</li>
                                    </ol>
                                </div>
                            </div>
                            {/* <!-- /breadcrumb --> */}

                            {/* <!-- row --> */}
                            <div class="row">
                                <div class="col-lg-4 col-xl-3">
                                    <div class="card custom-card">
                                        <div class="card-header">
                                            <div class="card-title">Settings</div>
                                        </div>
                                        <div class="main-content-left main-content-left-mail card-body">
                                            <div class="main-settings-menu">
                                                <nav class="nav main-nav-column">
                                                    <a class="nav-link thumb active mb-2" href="javascript:void(0);"><i
                                                        class="fe fe-home"></i> General Settings </a>
                                                    {/* <a class="nav-link thumb mb-2" href="javascript:void(0);"><i
                                                        class="fe fe-grid"></i> Emails</a>
                                                    <a class="nav-link thumb mb-2" href="javascript:void(0);"><i
                                                        class="fe fe-server"></i> General</a> */}
                                                    <a class="nav-link thumb mb-2" href="javascript:void(0);"><i
                                                        class="fe fe-bell"></i> Notifications</a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-xl-9">
                                    <div class="card custom-card">
                                        <div class="card-header">
                                            <div class="card-title">Overview</div>
                                            <p>Used to customize and manage all settngs about this Dashboard</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 p-2">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="d-flex">
                                                                <div class="settings-main-icon me-4 mt-1"><i class="fe fe-home"></i>
                                                                </div>
                                                                <div>
                                                                    <p class="tx-20 font-weight-semibold d-flex mb-0">Dashboard</p>
                                                                    <p class="tx-13 text-muted mb-0">Dashboard Settings such as
                                                                        sidemenu,header and main page can be Controlled.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer p-3">
                                                    <a href="javascript:void(0);" class="tx-14 mb-0">Go to Settings</a>
                                                    <label class="custom-switch float-end mb-0">
                                                        <a href="javascript:void(0);" class="tx-14 mb-0 me-2">Restore default</a>
                                                        <input type="checkbox" name="custom-switch-checkbox3"
                                                            class="custom-switch-input"/>
                                                            <span class="custom-switch-indicator custom-radius"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 p-2">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="d-flex">
                                                                <div class="settings-main-icon me-4 mt-1"><i class="fe fe-grid"></i>
                                                                </div>
                                                                <div>
                                                                    <p class="tx-20 font-weight-semibold d-flex mb-0">Webapps</p>
                                                                    <p class="tx-13 text-muted mb-0">Web apps settings such as
                                                                        Apps,Elements,Advanced Ui & Mail can be Controlled.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer p-3">
                                                    <a href="javascript:void(0);" class="tx-14 mb-0">Go to Settings</a>
                                                    <label class="custom-switch float-end mb-0">
                                                        <a href="javascript:void(0);" class="tx-14 mb-0 me-2">Restore default</a>
                                                        <input type="checkbox" name="custom-switch-checkbox3"
                                                            class="custom-switch-input"/>
                                                            <span class="custom-switch-indicator custom-radius"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 p-2">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="d-flex">
                                                                <div class="settings-main-icon me-4 mt-1"><i
                                                                    class="fe fe-server"></i></div>
                                                                <div>
                                                                    <p class="tx-20 font-weight-semibold d-flex mb-0">General</p>
                                                                    <p class="tx-13 text-muted mb-0">General settings such as
                                                                        Icons,Charts,Ecommerce can be Controlled.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer p-3">
                                                    <a href="javascript:void(0);" class="tx-14 mb-0">Go to Settings</a>
                                                    <label class="custom-switch float-end mb-0">
                                                        <a href="javascript:void(0);" class="tx-14 mb-0 me-2">Restore default</a>
                                                        <input type="checkbox" name="custom-switch-checkbox3"
                                                            class="custom-switch-input" checked=""/>
                                                            <span class="custom-switch-indicator custom-radius"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 p-2">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="d-flex">
                                                                <div class="settings-main-icon me-4 mt-1"><i class="fe fe-flag"></i>
                                                                </div>
                                                                <div>
                                                                    <p class="tx-20 font-weight-semibold d-flex mb-0">Region &
                                                                        language</p>
                                                                    <p class="tx-13 text-muted mb-0">In this settings we can change
                                                                        the region and language manually.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer p-3">
                                                    <a href="javascript:void(0);" class="tx-14 mb-0">Go to Settings</a>
                                                    <label class="custom-switch float-end mb-0">
                                                        <a href="javascript:void(0);" class="tx-14 mb-0 me-2">Restore default</a>
                                                        <input type="checkbox" name="custom-switch-checkbox3"
                                                            class="custom-switch-input"/>
                                                            <span class="custom-switch-indicator custom-radius"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 p-2">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="d-flex">
                                                                <div class="settings-main-icon me-4 mt-1"><i class="fe fe-bell"></i>
                                                                </div>
                                                                <div>
                                                                    <p class="tx-20 font-weight-semibold d-flex mb-0">Notifiation
                                                                    </p>
                                                                    <p class="tx-13 text-muted mb-0">Notification settings we can
                                                                        control the notification privacy and security.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer p-3">
                                                    <a href="javascript:void(0);" class="tx-14 mb-0">Go to Settings</a>
                                                    <label class="custom-switch float-end mb-0">
                                                        <a href="javascript:void(0);" class="tx-14 mb-0 me-2">Restore default</a>
                                                        <input type="checkbox" name="custom-switch-checkbox3"
                                                            class="custom-switch-input"/>
                                                            <span class="custom-switch-indicator custom-radius"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 p-2">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="d-flex">
                                                                <div class="settings-main-icon me-4 mt-1"><i
                                                                    class="fe fe-settings"></i></div>
                                                                <div>
                                                                    <p class="tx-20 font-weight-semibold d-flex mb-0">Blog</p>
                                                                    <p class="tx-13 text-muted mb-0">In this settings we can modify
                                                                        all Blog related settings in this template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer p-3">
                                                    <a href="javascript:void(0);" class="tx-14 mb-0">Go to Settings</a>
                                                    <label class="custom-switch float-end mb-0">
                                                        <a href="javascript:void(0);" class="tx-14 mb-0 me-2">Restore default</a>
                                                        <input type="checkbox" name="custom-switch-checkbox3"
                                                            class="custom-switch-input" checked=""/>
                                                            <span class="custom-switch-indicator custom-radius"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--/ row --> */}


                        </div>
                        {/* <!-- /Container --> */}
                    </div>
                    {/* <!-- MAIN-CONTENT CLOSED --> */}

                    {/* <!--APP-SIDEBAR-RIGHT--> */}
                    <div class="sidebar sidebar-right sidebar-animate">
                        <div class="panel panel-primary card mb-0 box-shadow">
                            <div class="tab-menu-heading card-img-top-1 border-0 p-3">
                                <div class="card-title mb-0">Notifications</div>
                                <div class="card-options ms-auto">
                                    <a href="javascript:void(0);" class="sidebar-remove"><i class="fe fe-x"></i></a>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body latest-tasks p-0 border-0">
                                <div class="tabs-menu ">
                                    {/* <!-- Tabs --> */}
                                    <ul class="nav panel-tabs">
                                        <li class=""><a href="#side1" class="active" data-bs-toggle="tab">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg> */}
                                            Chat</a></li>
                                        <li><a href="#side2" data-bs-toggle="tab">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"/></svg> */}
                                            Notifications</a></li>
                                        <li><a href="#side3" data-bs-toggle="tab">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="side-menu__icon"  height="24" version="1.1" width="24"  viewBox="0 0 24 24"><path d="M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11C11.17,11 10.5,10.33 10.5,9.5C10.5,8.67 11.17,8 12,8C12.83,8 13.5,8.67 13.5,9.5C13.5,10.33 12.83,11 12,11Z" /></svg> */}
                                            Friends</a></li>
                                    </ul>
                                </div>
                                <div class="tab-content">
                                    <div class="tab-pane active " id="side1">
                                        <div class="list d-flex align-items-center border-bottom p-3">
                                            <div class="">
                                                <span class="avatar bg-primary brround avatar-md">CH</span>
                                            </div>
                                            <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                <p class="mb-0 d-flex ">
                                                    <b>New Websites is Created</b>
                                                </p>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex align-items-center">
                                                        <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                        <small class="text-muted ms-auto">30 mins ago</small>
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="list d-flex align-items-center border-bottom p-3">
                                            <div class="">
                                                <span class="avatar bg-danger brround avatar-md">N</span>
                                            </div>
                                            <a class="wrapper w-100 ms-3" href="javascript:void(0);" >
                                                <p class="mb-0 d-flex ">
                                                    <b>Prepare For the Next Project</b>
                                                </p>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex align-items-center">
                                                        <i class="mdi mdi-clock text-muted me-1 tx-11"></i>
                                                        <small class="text-muted ms-auto">2 hours ago</small>
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="tab-pane  " id="side2">
                                        <div class="list-group list-group-flush ">

                                            <div class="list-group-item d-flex  align-items-center">
                                                <div class="me-3">
                                                    <span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"><span class="avatar-status bg-success"></span></span>
                                                </div>
                                                <div>
                                                    <strong>Olivia</strong> Hey! there I' am available....
                                                    <div class="small text-muted">
                                                        12 mintues ago
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane  " id="side3">
                                        <div class="list-group list-group-flush ">

                                            <div class="list-group-item d-flex  align-items-center">
                                                <div class="me-2">
                                                    <span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/7.jpg"></span>
                                                </div>
                                                <div class="">
                                                    <div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
                                                </div>
                                                <div class="ms-auto">
                                                    <a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--/APP-SIDEBAR-RIGHT--> */}

                    {<Footer />}

                </div>

                {/* <!-- End Page --> */}

                {/* <!-- BACK-TO-TOP --> */}
                <a href="#top" id="back-to-top"><i class="las la-arrow-up"></i></a>

            </body>
        )
    }
}
export default ManageAccount