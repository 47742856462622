import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'
import DataLoader from '../../common/DataLoader'

class AllPaymentLinks extends Component {

    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        linkList: false,
        loading: true,

    }

    componentDidMount() {
        this.getPaymentLinks();
    }

    getPaymentLinks = async () => {
        const server_response = await ajax.listPaymentLinks(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                loading: false,
                linkList: server_response.details.content.list
            })

        } else {
            this.setState({
                loading: false,
                linkList: "404"
            })
        }
    }

    render() {
        const transactionLists = this.state.linkList
        return (
            <>

                {/* <!-- row  --> */}
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <div class="card">


                            {this.state.loading ? (<DataLoader />) :
                                <>

                                    <div class="card-header">
                                        <h4 class="card-title">Payment Links</h4>
                                    </div>


                                    <div class="card-body pt-0">
                                        <div class="table-responsive">
                                            <table class="table  table-bordered text-nowrap mb-0"> {/* id="example1" add filters to the table */}
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th>LINK LOGO</th>
                                                        <th>LINK NAME</th>
                                                        <th>LINK DURATION</th>
                                                        <th>LINK AMOUNT</th>
                                                        <th>LINK TYPE</th>
                                                        <th>STATUS</th>
                                                        <th>CREATED AT</th>
                                                        <th>ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {transactionLists && transactionLists !== "404" &&
                                                        transactionLists.map((listValue, index) =>

                                                            <tr>
                                                                <td class="text-center">{index + 1}</td>
                                                                <td>
                                                                    <div class="avatar-sm  rounded-circle">
                                                                        <img alt="avatar" class="rounded-circle" src={listValue.link_icon.file_path} />
                                                                    </div> 
                                                                </td>
                                                                <td>{listValue.link_name}</td>
                                                                <td>{listValue.link_duration} hr(s)</td>
                                                                <td>{listValue.link_amount.amount_c} UGX</td>
                                                                <td>
                                                                {(listValue.link_type === "0" ? <span class="badge badge-success">Permanent</span> : (listValue.link_type === "1" ? (<span class="badge badge-warning">Temporary</span>) : null))}
                                                                </td>
                                                                <td>
                                                                {(listValue.status === "0" ? <span class="badge badge-danger">Expired</span> : (listValue.status === "1" ? (<span class="badge badge-success">Active</span>) : null))}
                                                                </td>
                                                                <td>{listValue.created_at.long_date}</td>
                                                                <td><i class="icon-pen"></i></td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            }



                        </div>
                    </div>
                </div>
                {/* <!-- /row --> */}

            </>
        )
    }
}
export default AllPaymentLinks