import React, { Component } from 'react'
import { Modal, Select, Table } from 'antd'
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from './Notification'
import functions from '../utils/functions'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css"
import ajax from '../utils/ajax'

const { Option } = Select;

class PaymentLinkDialog extends Component {
    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        mobileTransfer: false,
        amountHolder: false,
        bankTransfer: false,
        showMsg: false,
        dialog: false,
        temp: false,
        showMsg: false,
        linkIcon: "", linkName: "", linkDuration: "", linkAmount: "", linkType: ""
    }

    handleCancel = () => {
        const sent = this.props
        this.setState({ visible: false })
        sent.handleCancel(false)
    }

    componentDidMount = () => {
        //setTimeout(function () { window.location.reload(1); }, 5000);
    }

    onChangeLinkIcon = (event) => {
        if (event != null) {

            let files = event.target.files;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(files[0]);

            fileReader.onload = (event) => {
                this.setState({
                    linkIcon: event.target.result,
                })
            }

        }

    }

    onChangeLinkName = (event) => {

        if (event != null) {
            this.setState({
                linkName: event.target.value
            })
        }

    }

    onChangeDuration = (event) => {

        if (event != null) {
            this.setState({
                linkDuration: event.target.value
            })
        }

    }

    onChangeAmount = (event) => {

        if (event != null) {
            this.setState({
                linkAmount: event.target.value
            })
        }
    }

    onChangeLinkType = (event) => {
        if (event.target.value != "") {
            this.setState({
                temp: event.target.value,
                linkType: event.target.value
            })
        }
    }

    onPressProccess = async (event) => {

        event.preventDefault()
        const { linkIcon, linkName, linkDuration, linkAmount, linkType } = this.state

        if (linkIcon.length > 0 && linkName.length > 0, linkDuration.length > 0, linkAmount.length > 0, linkType.length > 0) {

            this.setState({
                load: true,
                showMsg: false
            })

            //process transfer from the API
            const server_response = await ajax.createPaymentLink(linkIcon, linkName, linkDuration, linkAmount, linkType)

            if (server_response.status === 'OK') {

                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: true,
                    showMsg: true
                })

                setTimeout(function () { window.location.reload(1); }, 5000);

            } else {
                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: false,
                    showMsg: true
                })
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'All fields are required',
                load: false,
            })
        }


    }

    render() {
        return (

            <Modal
                title={"Create Payment Link(s)"}
                centered
                bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
                visible={this.props.visible.visible}
                maskClosable={false}
                footer={[null]}
                maskTransitionName=""
                onCancel={this.handleCancel}
                width={500}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: false }}
            >

                {this.state.showMsg ? (
                    <>
                        <div style={{ height: "20px" }}>
                            <Notification
                                message={{
                                    type: this.state.type,
                                    info: this.state.info,
                                }}
                            />
                        </div>
                        <br /><br />
                    </>) : null}

                <form class="form-horizontal" method="post" onSubmit={this.onPressProccess}>

                    <div class="modal-body">
                        <div class="row">

                            <div class="form-group col-md-12">
                                <label for="validationDefaultUsername" class="form-label">Payment Link Name </label>
                                <div class="input-group">
                                    {/* <span class="input-group-text" id="inputGroupPrepend2">@</span> */}
                                    <input type="text" onChange={this.onChangeLinkName} class="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" required />
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="validationDefaultUsername" class="form-label">Payment Link Logo / Icon</label>
                                <div class="input-group">
                                    <input class="form-control" type="file" id="formFile" onChange={this.onChangeLinkIcon} />
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="validationDefaultUsername" class="form-label">Payment Link type</label>
                                <div class="input-group">
                                    <select name="country" class="form-control form-select" data-bs-placeholder="Select Country" onChange={this.onChangeLinkType}>
                                        <option selected>Select Link Type</option>
                                        <option value="1">Temporary</option>
                                        <option value="0">Permanent</option>
                                    </select>
                                </div>
                            </div>

                            {this.state.temp == "1" ? (
                                <>
                                    <div class="form-group col-md-12">
                                        <label for="validationDefaultUsername" class="form-label">Payment Link Duration [ Enter number of hours ]</label>
                                        <div class="input-group">
                                            {/* <span class="input-group-text" id="inputGroupPrepend2">@</span> */}
                                            <input type="number" onChange={this.onChangeDuration} class="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" required />
                                        </div>
                                    </div>

                                    <div class="input-group">
                                        <div class="input-group-text">
                                            <i class="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                        </div>
                                        <Datetime
                                            className='' />
                                    </div>

                                </>
                            ) : null}

                            <div class="form-group col-md-12">
                                <label for="validationDefaultUsername" class="form-label">Payment Link Amount</label>
                                <div class="input-group">
                                    <input type="text" onChange={this.onChangeAmount} class="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" required />
                                </div>

                            </div>

                        </div>
                        {/* </form> */}

                        <button class="btn ripple btn-secondary" disabled={this.state.load} type="submit">

                            {this.state.load ? (<>
                                <span style={{ margin: '0 20px' }} class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Creating link please wait ...
                            </>
                            ) : (<>Create Link</>)}

                        </button>

                    </div>
                </form>

            </Modal>

        )
    }
}

export default PaymentLinkDialog