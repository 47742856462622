import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import functions from '../utils/functions'

class SideNav extends Component {

    state = {
        username: '',
        password: '',
        type: '',
        info: '',
        load: '',
    }

    render() {
        const roleList = functions.roleGuard()
        // console.log("hyhuytu ",window.location.pathname)

        return (
            <div>
                {/* <!--APP-SIDEBAR--> */}
                < div class="sticky">
                    <aside class="app-sidebar">
                        <div class="main-sidebar-header active">
                            <Link class="header-logo active" to={roleList == "CLIENT" ? '/client/home' : '/'}>
                                <img src="../assets/img/brand/logo.png" class="main-logo  desktop-logo" alt="logoAA" />
                                <img src="../assets/img/brand/logo-white.png" class="main-logo  desktop-dark" alt="logoBB" />
                                <img src="../assets/img/brand/logo-white.png" class="main-logo  mobile-logo" alt="logo" />
                                <img src="../assets/img/brand/logo-white.png" class="main-logo  mobile-dark" alt="logo" />
                            </Link>
                        </div>
                        <div class="main-sidemenu">
                            <div class="slide-left disabled" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"><path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" /></svg></div>
                            <ul class="side-menu">
                                <li class="side-item side-item-category">Main</li>

                                <li class="slide">
                                    <Link to={roleList == "CLIENT" ? '/client/home' : '/'} class={window.location.pathname == "/" || window.location.pathname == "/client/home" ? 'side-menu__item active' : 'side-menu__item'}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" class="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg> */}
                                        <i class='fe fe-home'> </i> &nbsp; &nbsp;
                                        <span class="side-menu__label">Dashboard</span>
                                    </Link>
                                </li>


                                {roleList == "SUPER" &&
                                    <>
                                        <li class="side-item side-item-category">CLIENTS</li>
                                        <li class="slide">
                                            <Link to="/manage-clients" class={window.location.pathname == "/manage-clients" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class='fe fe-command'> </i> &nbsp; &nbsp;
                                                <span class="side-menu__label active" style={{ marginLeft: "10" }}> Manage</span>
                                            </Link>
                                        </li>

                                        <li class="side-item side-item-category">USERS</li>

                                        <li class="slide">
                                            <Link to="/manage-users" class={window.location.pathname == "/manage-users" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class='fe fe-grid'> </i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Manage</span>
                                            </Link>
                                        </li>
                                    </>
                                }

                                {roleList == "SUPER" &&
                                    <>
                                        <li class="side-item side-item-category">FINANCE</li>

                                        <li class="slide">
                                            <Link to="/all-transactions" class={window.location.pathname == "/all-transactions" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-graph"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> All Transactions</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="#/all-transactions" class="side-menu__item">
                                                <i class="si si-share"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Pending Transactions</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="#/all-transactions" class="side-menu__item">
                                                <i class="si si-graph"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Failed Transactions</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/account-statement" class={window.location.pathname == "/account-statement" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-list"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Statement</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/transfer-out" class={window.location.pathname == "/transfer-out" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-share-alt"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Transfers</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/payment-links" class={window.location.pathname == "/payment-links" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-share-alt"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Payment Links</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/system-reports" class={window.location.pathname == "/system-reports" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-chart"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label">General Reports</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/custome-system-reports" class={window.location.pathname == "/custome-system-reports" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-pie-chart"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Specific Reports</span>
                                            </Link>
                                        </li>

                                        <li class="side-item side-item-category">SYSTEM</li>
                                        <li class="slide">

                                            <Link to="/account-managment" class={window.location.pathname == "/account-managment" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class='fe fe-settings'></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Settings</span>
                                            </Link>
                                        </li>
                                    </>
                                }

                                {roleList == "CLIENT" &&
                                    <>
                                        <li class="side-item side-item-category">FINANCE</li>

                                        <li class="slide">
                                            <Link to="/all-transactions" class={window.location.pathname == "/all-transactions" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="icon-organization"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> All Transactions</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/success-transactions" class={window.location.pathname == "/success-transactions" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="icon-grid"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Successful Transactions</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/pending-transactions" class={window.location.pathname == "/pending-transactions" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="icon-chart"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Pending Transactions</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/failed-transactions" class={window.location.pathname == "/failed-transactions" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="icon-chart"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Failed Transactions</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/account-statement" class={window.location.pathname == "/account-statement" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-list"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Statement</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/transfer-out" class={window.location.pathname == "/transfer-out" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-share-alt"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Transfers</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/payment-links" class={window.location.pathname == "/payment-links" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-share-alt"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Payment Links</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/system-reports" class={window.location.pathname == "/system-reports" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-chart"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label">General Reports</span>
                                            </Link>
                                        </li>

                                        <li class="slide">
                                            <Link to="/custome-system-reports" class={window.location.pathname == "/custome-system-reports" ? 'side-menu__item active' : 'side-menu__item'}>
                                                <i class="si si-pie-chart"></i> &nbsp; &nbsp;
                                                <span class="side-menu__label"> Specific Reports</span>
                                            </Link>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div class="slide-right" id="slide-right">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" /></svg>
                            </div>
                        </div>
                    </aside>
                </div>
                {/* <!--/APP-SIDEBAR--> */}

            </div>
        )
    }
}
export default SideNav

