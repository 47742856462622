import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import Notification from '../../common/Notification'
//import Loader from '../../common/Loader'
import ajax from '../../../utils/ajax'
import functions from '../../../utils/functions'

class AdminStats extends Component {

    state = {
        userId: functions.sessionGuard(),
        userRole: functions.roleGuard(),
        username: '',
        gross: "00",
        actual: "00",
        current:"00",
        expected: "00",
        missing: "00",
        principle: "00",
        failed: "00",
        netted: "00",
        transactionList: false
    }

    componentDidMount() {
        this.getUser();
        this.getGross();
        this.getPrinciple();
        this.getFailed();
        this.getNet();
        this.getCurrentBalance();
    }


    getUser = async () => {
        const server_response = await ajax.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                username: server_response.details.content.username,
                roleName: server_response.details.content.user_role.role_name,
                photo: server_response.details.content.profile_photo.file_path

            })
            //console.log("#############", server_response.details.content)
        } else {
            // console.log(server_response)
        }
    }

    getGross = async () => {
        const server_response = await ajax.getGross();
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                gross: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getPrinciple = async () => {
        const server_response = await ajax.getPrinciple();
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                principle: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getFailed = async () => {
        const server_response = await ajax.getFailed();
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                failed: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getNet = async () => {
        const server_response = await ajax.getNet();
        if (server_response.status === "OK") {
            //console.log("kakakakakakakakakkakakakakakk ",server_response.details.content)
            this.setState({
                netted: server_response.details.content.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    getCurrentBalance = async () => {
        const server_response = await ajax.accountBalance();
        if (server_response.status === "OK") {
            //console.log("jumiaaa ",server_response.details.content)
            this.setState({
                actual: server_response.details.content.true_balance.amount_c,
                expected: server_response.details.content.apparent_balance.amount_c,
                missing: server_response.details.content.missing_balance.amount_c,
                current:server_response.details.content.current_balance.amount_c

            })
        } else {
            // console.log(server_response)
        }
    }

    render() {
        return (
            <>
                <div class="row">
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        <div class="card primary-custom-card1">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                                        <div class="prime-card"><img class="img-fluid" src="https://php.spruko.com/nowa/nowa/assets/img/png/dashboard8.png" style={{ height: '320px' }} alt="" /></div>
                                    </div>
                                    <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12">
                                        <div class="text-justified align-items-center">
                                            <h2 class="text-dark font-weight-semibold mb-3 mt-2">Hi, Welcome Back <span class="text-primary">{this.state.username}!</span></h2>
                                            <p class="text-dark tx-17 mb-2 lh-3"> You have 10% increase in activity. Please have a sneakpic to know whats happening.</p>
                                            <p class="font-weight-semibold tx-12 mb-4">For billing related queries, contact us through support mail us at support@paybunny.com </p>
                                            <br /> <br />
                                            <button class="btn btn-primary mb-3 shadow">Upgrade to new Plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        {/* <!-- <div class="container"> --> */}
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image1">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12 ">Gross PayIn</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-20 font-weight-semibold mb-2">$ {this.state.gross}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-shopping-bag tx-16 text-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image2">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12">Principle PayIn</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-20 font-weight-semibold mb-2">$ {this.state.principle}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-shopping-bag tx-16 text-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image3">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12">Collection Charge</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-20 font-weight-semibold mb-2">$ {this.state.netted}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-shopping-bag tx-16 text-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div class="card sales-card circle-image4">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="ps-4 pt-4 pe-3 pb-4">
                                                <div class="">
                                                    <h6 class="mb-2 tx-12">All Failed Collections</h6>
                                                </div>
                                                <div class="pb-0 mt-0">
                                                    <div class="d-flex">
                                                        <h4 class="tx-22 font-weight-semibold mb-2">$ {this.state.failed}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                                                <i class="fe fe-external-link tx-16 text-secondary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            {/* <div class="feature-1 ms-auto"> */}
                                                <div class="">
                                                    <span class="text-start">
                                                        <i class="icon icon-user  text-success me-2 text-danger"></i><b>Discrepancy: &nbsp; $ {this.state.missing}</b></span>
                                                    <p class="mt-4"><i class="icon icon-user-female  text-danger me-2"></i><b>Balance: &nbsp; $ {this.state.current} </b> </p>

                                                </div>
                                            {/* </div> */}
                                            <div class="feature-1 ms-auto">
                                                <div class="feature">
                                                    <span class="text-start">
                                                        <i class="icon icon-user  text-success me-2"></i><b>Gross PayOut: &nbsp; $ {this.state.expected}</b></span>
                                                    <p class="mt-4"><i class="icon icon-user-female  text-danger me-2"></i><b>Net PayOut: &nbsp; $ {this.state.actual} </b> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- </div> --> */}
                </div>
            </>
        )
    }
}
export default AdminStats