import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return (
            <>
                {/* <!-- FOOTER --> */}
                <div class="main-footer">
                    <div class="container-fluid pt-0 ht-100p">
                        Copyright © {new Date().getFullYear() }<a href="javascript:void(0);" class="text-primary"> payBunny</a>. All rights reserved
                    </div>
                </div>
                {/* <!-- FOOTER END --> */}
            </>
        )
    }
}
export default Footer