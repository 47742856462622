// import Cookies from "universal-cookie";
import $ from 'jquery';
import dictionary from './dictionary';

export default {

    parseJwt(token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    },

    sessionGuard() {
        if (!localStorage.getItem('paybunny@user')) {
            return false;
        } else {
            const access_token = localStorage.getItem('paybunny@user');
            const decorded_token = this.parseJwt(access_token);
            // console.log(decorded_token)
            return decorded_token['data']['user_id'];
        }
    },

    loggedInUser() {
        if (!localStorage.getItem('paybunny@user')) {
            return false;
        } else {
            const access_token = localStorage.getItem('paybunny@user');
            const decorded_token = this.parseJwt(access_token);
            return decorded_token['data']['username'];
        }
    },

    permissionGuard() {
        if (!localStorage.getItem('paybunny@user')) {
            return false;
        } else {
            const access_token = localStorage.getItem('paybunny@user');
            const decorded_token = this.parseJwt(access_token);
            // console.log(decorded_token)
            const permission = decorded_token['data']['user_permissions'];
            return permission;
        }
    },

    roleGuard() {
        if (!localStorage.getItem('paybunny@user')) {
            return false;
        } else {
            const access_token = localStorage.getItem('paybunny@user');
            const decorded_token = this.parseJwt(access_token);
            // console.log(decorded_token)
            const permission = decorded_token['data']['role_code'];
            return permission;
        }
    },

    db_date(objDate) {
        const month = (objDate.getMonth() + 1).toString();
        const newMonth = month.length === 1 ? "0" + month : month

        const day = objDate.getDate().toString();
        const newDay = day.length === 1 ? "0" + day : day

        const newDate = objDate.getFullYear().toString() + "-" + newMonth + "-" + newDay
        return newDate;
    },
    simple_date() {
        const longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const longMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const today = new Date();
        const day = today.getDate().toString();
        const newDay = day.length === 1 ? "0" + day : day
        const date = longDays[today.getDay()] + ', ' + newDay + ' ' + longMonths[today.getMonth()];
        return date;

    },

    uCfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    baseValue(value) {
        if (value < 10) {
            return 0;
        } else if (value < 20) {
            return 10;
        } else if (value < 30) {
            return 20;
        } else if (value < 40) {
            return 30;
        } else if (value < 50) {
            return 40;
        } else if (value < 60) {
            return 50;
        } else if (value < 70) {
            return 60;
        } else if (value < 80) {
            return 70;
        } else if (value < 90) {
            return 80
        }
        else if (value < 100) {
            return 90;
        } else {
            return 100;
        }
    },
    handleFocus(event) {
        event.target.select()
    },

    findInObject(object, value) {
        for (var i = 0; i < object.length; i++) {
            var exists = object[i].permission === value;
            if (exists) {
                return true
            }
        }

        return false;

    },

    findInPermission(object, value) {
        for (var i = 0; i < object.length; i++) {
            if (object[i].permission == value) {
                return true
            }
        }
        return false;
    },

    getSiblings(elem) {

        // Setup siblings array and get the first sibling
        var siblings = [];
        var sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling
        }

        return siblings;

    },
    removeClassFromElementSiblings(elem, selectorClass) {

        // Setup siblings array and get the first sibling
        // var siblings = [];
        var sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                sibling.classList.remove(selectorClass)
            }
            sibling = sibling.nextSibling
        }

    },
    closeSystemDrawer() {
        document.getElementById('system_drawer').classList.remove('sidebar-open')
    },
    removeClassFromElements(elementClass, selectorClass) {
        // Setup siblings array and get the first sibling
        var sibling = document.getElementsByClassName(elementClass);

        for (var i = 0; i < sibling.length; i++) {
            sibling[i].classList.remove(selectorClass)
        }
    },
    closeHeaderDropdowns() {
        var sibling = document.getElementsByClassName('header-dropdown');

        for (var i = 0; i < sibling.length; i++) {
            sibling[i].classList.remove('show')
        }
    },

    hide(id) {
        document.getElementById(id).style.display = 'none'
    },
    closePopup(id) {
        $("#" + id).modal('hide');
    },
    openPopup(id) {
        $("#" + id).modal('show');
    },

    printPOS(ele) {

        var sOption = "toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes,width=300,height=600,left=100,top=25";
        var sWinHTML = document.getElementById(ele).innerHTML;
        var winprint = window.open("", "Print", sOption);
        winprint.document.open();
        winprint.document.write('<html><head>');
        winprint.document.write('<style type="text/css">');
        winprint.document.write('.break_page{page-break-after: always; };');
        winprint.document.write('.invoice-POS{padding:2mm;margin: 0 auto;width: 44mm;background: #FFF;}');
        winprint.document.write('.invoice-POS ::selection {background: #f31544; color: #FFF;}');
        winprint.document.write('::moz-selection {background: #f31544; color: #FFF;}');
        winprint.document.write('.invoice-POS h1{font-size: 1.5em;color: #222;}');
        winprint.document.write('.invoice-POS h2{font-size: .9em;}');
        winprint.document.write('.invoice-POS h3{font-size: 1.2em;font-weight: 300;line-height: 2em;}');
        winprint.document.write('.invoice-POS p{font-size: .7em;color: #666;line-height: 1.2em;}');
        winprint.document.write('.invoice-POS #top, #mid,#bot{border-bottom: 1px solid #EEE;}');
        winprint.document.write('.invoice-POS #top{min-height: auto;}');
        winprint.document.write('.invoice-POS #mid{min-height: 50px; text-align: center;}');
        winprint.document.write('.invoice-POS #bot{ min-height: auto;}');
        winprint.document.write('.invoice-POS #top .logo{height: 60px;width: 60px;background: url(http://parking.thrivetecdev.com/PMIS-API/assets/img/logo_d.png) no-repeat;background-size: 60px 60px;}');
        winprint.document.write('.invoice-POS .clientlogo{float: left;height: 60px;width: 60px;background-size: 60px 60px;border-radius: 50px;}');
        winprint.document.write('.invoice-POS .info{display: block;margin-left: 0;}');
        winprint.document.write('.invoice-POS .title{float: right;}');
        winprint.document.write('.invoice-POS .title p{text-align: right;} ');
        winprint.document.write('.invoice-POS table{width: 100%;border-collapse: collapse;}');
        winprint.document.write('.invoice-POS .tabletitle{font-size: .5em;background: #EEE;}');
        winprint.document.write('.invoice-POS .service{border-bottom: 1px solid #EEE;}');
        winprint.document.write('.invoice-POS .item{width: 24mm;}');
        winprint.document.write('.invoice-POS .itemtext{font-size: .6em;}');
        winprint.document.write('.invoice-POS #legalcopy{margin-top: 5mm;}');
        winprint.document.write('.invoice-POS .company-info{margin-top: -8px;}');
        winprint.document.write('.invoice-POS .doc-type{margin-top: -14px;padding-bottom: 10px;font-size: 11px;text-transform: uppercase;}');

        winprint.document.write('</style></head><body onload="window.print();">');
        winprint.document.write(sWinHTML);
        winprint.document.write('</body></html>');
        winprint.document.close();
        winprint.focus();
        //winprint.close();
    },


    userBoundary() {
        if (!localStorage.getItem('paybunny@user')) {
            return false;
        } else {
            const access_token = localStorage.getItem('paybunny@user');
            const decorded_token = this.parseJwt(access_token);
            return decorded_token['data']['boundary'];
        }
    },

    

    printDoc(ele) {
        

        var sOption = "toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes,width=300,height=600,left=100,top=25";
        var sWinHTML = document.getElementById(ele).innerHTML;
        var winprint = window.open("", "Print", sOption);
        winprint.document.open();
        winprint.document.write('<html><head>');
        winprint.document.write('<link rel="stylesheet" type="text/css" href="'+ dictionary.apiAssetsRoot + 'css/cssForPMIS/style.css" />');
        winprint.document.write('<link rel="stylesheet" type="text/css" href="'+ dictionary.apiAssetsRoot +'css/css/App.css" />');
        winprint.document.write('<style type="text/css">');
        winprint.document.write('.break_page{page-break-after: always; };');
    //     winprint.document.write('.card { border-radius: 7px !important;border: 1px solid transparent !important;transition: 0.4s;};');
    //     winprint.document.write('.card-body { padding: 1.7rem !important;}');
    //     winprint.document.write('.d-lg-flex { display: flex !important; }');
    //     winprint.document.write('.card .card-title { font-size: 1rem !important; font-weight: 500;}');
    //     winprint.document.write('.row{ display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px;}');
    //     winprint.document.write('.table-invoice thead>tr>th, .table-invoice thead>tr>td {font-size: 11px;font-weight: 700;color:#334151;}');
    //     winprint.document.write('.table-invoice tbody>tr>th:first-child, .table-invoice tbody>tr>td:first-child {color:#334151;font-weight: 500;}');
    //     winprint.document.write('.table-invoice tbody>tr>th:first-child .invoice-notes, .table-invoice tbody>tr>td:first-child .invoice-notes {margin-right: 20px;}');
    //     winprint.document.write('.table-invoice tbody>tr>th:first-child .invoice-notes .section-label-sm, .table-invoice tbody>tr>td:first-child .invoice-notes .section-label-sm {font-weight: 600;}');
    //     winprint.document.write('.table-invoice tbody>tr>th:first-child .invoice-notes p, .table-invoice tbody>tr>td:first-child .invoice-notes p {font-size: 13px;font-weight: 400;color:#334151;}');
    //    /*cell Width */ winprint.document.write('.wd-35p {width: 35%;}.wd-30p { width: 30%;}.wd-20p {width: 20%;}.wd-25p {width: 25%;}');
    //    /*font size */ winprint.document.write('.tx-12 {font-size: 12px;} .tx-12-f {font-size: 12px !important;}');
    //    winprint.document.write('.valign-middle { vertical-align: middle;}');
    //    winprint.document.write('.table-bordered {border: 1px solid #e1e6f1;}.table-bordered th, .table-bordered td { border: 1px solid #e1e6f1;}.table-bordered thead th, .table-bordered thead td {border-bottom-width: 2px;}');

        winprint.document.write('</style></head><body onload="window.print();">');
        winprint.document.write(sWinHTML);
        winprint.document.write('</body></html>');
        winprint.document.close();
        winprint.focus();
        //winprint.close();
        console.log(winprint)
    },
}
