import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../common/Footer'
import Header from '../common/Header'
import SideNav from '../common/SideNav'
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import ResetDialog from '../common/AddClient'

class AccountSettings extends Component {

	state = {
		userId: functions.sessionGuard(),
		username: '',
		showReset: false
	}

	componentDidMount() {

		if (!this.props.location.state) {
			this.props.history.push('/manage-clients');
		} else {
			this.setState({
				clientId: this.props.location.state.client,
				fullname: this.props.location.state.fullname,
				phone: this.props.location.state.phone,
				email: this.props.location.state.email,
				address: this.props.location.state.address,
				clientuser: this.props.location.state.username
			})
			//, () => this.listSessionsInLocations(this.props.location.state.locationID)
		}
	}

	enableEdit = () => {
		this.setState({
			editing: true
		})
	}

	resetDialog = () => {
		alert("this is me")
		this.setState({
			showReset: true
		})
	}

	updates = () => {
		this.setState({
			editing: false
		})
	}

	render() {
		return (
			<body class="ltr main-body app sidebar-mini">

				{/* <!-- GLOBAL-LOADER --> */}
				{/* <div id="global-loader">
					<img src="https://php.spruko.com/nowa/nowa/assets/img/loader.svg" class="loader-img" alt="Loader" />
				</div> */}
				{/* <!-- /GLOBAL-LOADER --> */}

				{/* <!-- Page --> */}
				<div class="page">

					{<Header />}
					{<SideNav />}

					{/* <!-- MAIN-CONTENT --> */}
					<div class="main-content app-content">

						{/* <!-- container --> */}
						<div class="main-container container-fluid">

							{/* <!-- breadcrumb --> */}
							<div class="breadcrumb-header justify-content-between">

							</div>
							{/* <!-- /breadcrumb --> */}

							<div class="row">
								<div class="col-lg-12 col-md-12">
									<div class="card custom-card">
										<div class="card-body d-md-flex">

											<div class="">
												<span class="profile-image pos-relative">
													<img class="br-5" alt="" src="https://php.spruko.com/nowa/nowa/assets/img/faces/4.jpg" />
													<span class="bg-success text-white wd-1 ht-1 rounded-pill profile-online"></span>
												</span>
											</div>

											<div class="my-md-auto mt-4 prof-details" >
												<h4 class="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">{this.state.fullname}</h4>
												<p class="tx-13 text-muted ms-md-4 ms-0 mb-2 pb-2 ">
													{/* <span class="me-3"><i class="far fa-address-card me-2"></i>Ui/Ux
														Developer</span> */}
													<span class="me-3"><i class="fa fa-map me-2"></i>Uganda</span>
													<span><i class="far fa-flag me-2"></i>{this.state.address}</span>
												</p>
												<p class="text-muted ms-md-4 ms-0 mb-2"><span><i
													class="fa fa-phone me-2"></i></span><span
														class="font-weight-semibold me-2">Phone:</span><span>{this.state.phone}</span>
												</p>
												<p class="text-muted ms-md-4 ms-0 mb-2"><span><i
													class="fa fa-envelope me-2"></i></span><span
														class="font-weight-semibold me-2">Email:</span><span>{this.state.email}</span>
												</p>
												<p class="text-muted ms-md-4 ms-0 mb-2"><span><i
													class="fa fa-globe me-2"></i></span><span
														class="font-weight-semibold me-2">Website</span><span>sprukotechnologies</span>
												</p>
											</div>

											<div class="col-lg-7 col-md-6" style={{ display: 'flex', justifyContent: 'right', height: '40px' }} >
												<button class="btn btn-warning pd-x-30 mg-t-5" data-bs-target="#Vertically" data-bs-toggle="modal"><i class="fa fa fa-lock"></i> Request Password Reset</button>
											</div>
										</div>

										<div class="card-footer py-0">
											<div class="profile-tab tab-menu-heading border-bottom-0">
												<nav class="nav main-nav-line p-0 tabs-menu profile-nav-line border-0 br-5 mb-0	">
													<a class="nav-link  mb-2 mt-2 active" data-bs-toggle="tab" href="#settings">Account
														Settings</a>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <!-- row --> */}
							<div class="row">
								<div class="col-lg-6 col-md-6">
									<div class="card custom-card">
										<div class="card-body">
											<div class="main-content-label mg-b-5">
												Account Information
											</div>
											<div class="pd-30 pd-sm-20">
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Fullname</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value={this.state.fullname} type="text" />
													</div>
												</div>
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Username</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value={this.state.clientuser} type="text" />
													</div>
												</div>
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Phone Number</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value={this.state.phone} type="phone" />
													</div>
												</div>
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Email Address</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value={this.state.email} type="email" />
													</div>
												</div>
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Physical Address</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value={this.state.address} type="email" />
													</div>
												</div>
												{this.state.editing ? (
													<button onClick={this.updates} class="btn btn-primary pd-x-30 mg-t-5">Update</button>
												) : (<button onClick={this.enableEdit} class="btn btn-secondary pd-x-30 mg-r-5 mg-t-5">Edit</button>)}
												<br /><br />


											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-6 col-md-6">
									<div class="card">
										<div class="card-body">
											<div class="main-content-label mg-b-5">
												Collection Rates
											</div>
											<div class="pd-30 pd-sm-20">
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Paying {'<='} UGX 2O,OOO</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value="UGX 200" type="text" />
													</div>
												</div>
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Paying {'>='} UGX 2O,OOO</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value="2% of payment" type="text" />
													</div>
												</div>
											</div>
										</div>
									</div>


									<div class="card">
										<div class="card-body">
											<div class="main-content-label mg-b-5">
												Security Key
											</div>
											<div class="pd-30 pd-sm-20">
												<div class="row row-xs align-items-center mg-b-20">
													<div class="col-md-4">
														<label class="form-label mg-b-0">Key</label>
													</div>
													<div class="col-md-8 mg-t-5 mg-md-t-0">
														<input class="form-control" value="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImlhdCI6MTY2NTA1MDE1MywiZXhwIjoxNjY1MDUwMjEzLCJ1c2VyX2lkIjoiMjg2Iiwicm9sZV9pZCI6IjQiLCJyb2xlX2NvZGUiOiJaVUxVIiwiaXNfYWN0aXZlIjoiMSIsImlzX3NlY3VyZSI6IjEiLCJ1c2VybmFtZSI6ImJ1dWxlIiwiYm91bmRhcnkiOiJOT1JNQUwiLCJicmFuY2hfaWQiOiIzIiwiZnVsbF9uYW1lIjoiQnV1bGUifX0.3V4hP5jh7-txYc4SLE6t7U0YIrTjS0eWmKBTe1N4fzM" type="text" />
													</div>
												</div>
											</div>
											<button class="btn ripple btn-secondary">Generate New Token</button>
										</div>
									</div>

								</div>

							</div>
							{/* <!-- /row --> */}


						</div>
						{/* <!-- Container closed --> */}

						<div class="modal fade" id="Vertically">
							<div class="modal-dialog modal-md modal-dialog-centered" role="document">
								<div class="modal-content modal-content-demo">
									<div class="modal-header">
										<h6 class="modal-title">Request Password Reset # {"[ A reset code will be sent ]"}</h6><button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
									</div>

									<div class="modal-body">
										<form class="form-horizontal">
											<div class="row">
												<div class="form-group col-md-12">

													<label for="validationDefaultUsername" class="form-label">Username</label>
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="inputGroupPrepend2">@</span>
                                                        <input type="text" value={this.state.clientuser} class="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" disabled />
                                                    </div>

												</div>
												</div>
										</form>

									</div>
									<div class="modal-footer">
										<button class="btn ripple btn-secondary" data-bs-dismiss="modal" type="button">Request Reset</button>
									</div>
								</div>
							</div>
						</div>

					</div>
					{/* <!-- MAIN-CONTENT CLOSED --> */}

					{/* {this.state.showReset ? (<ResetDialog/>) : null} */}

					{/* <!--APP-SIDEBAR-RIGHT--> */}
					<div class="sidebar sidebar-right sidebar-animate">
						<div class="panel panel-primary card mb-0 box-shadow">
							<div class="tab-menu-heading card-img-top-1 border-0 p-3">
								<div class="card-title mb-0">Notifications</div>
								<div class="card-options ms-auto">
									<a href="javascript:void(0);" class="sidebar-remove"><i class="fe fe-x"></i></a>
								</div>
							</div>
							<div class="panel-body tabs-menu-body latest-tasks p-0 border-0">
								<div class="tabs-menu ">
									{/* <!-- Tabs --> */}
									{/* <ul class="nav panel-tabs">
								<li class=""><a href="#side1" class="active" data-bs-toggle="tab"><svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg> Chat</a></li>
								<li><a href="#side2" data-bs-toggle="tab"><svg xmlns="http://www.w3.org/2000/svg"  class="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"/></svg> Notifications</a></li>
								<li><a href="#side3" data-bs-toggle="tab"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="side-menu__icon"  height="24" version="1.1" width="24"  viewBox="0 0 24 24"><path d="M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11C11.17,11 10.5,10.33 10.5,9.5C10.5,8.67 11.17,8 12,8C12.83,8 13.5,8.67 13.5,9.5C13.5,10.33 12.83,11 12,11Z" /></svg> Friends</a></li>
						</ul> */}
								</div>
								<div class="tab-content">
									<div class="tab-pane active " id="side1">
										<div class="list d-flex align-items-center border-bottom p-3">
											<div class="">
												<span class="avatar bg-primary brround avatar-md">CH</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>New Websites is Created</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">30 mins ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
										<div class="list d-flex align-items-center border-bottom p-3">
											<div class="">
												<span class="avatar bg-danger brround avatar-md">N</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>Prepare For the Next Project</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">2 hours ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
										<div class="list d-flex align-items-center border-bottom p-3">
											<div class="">
												<span class="avatar bg-info brround avatar-md">S</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>Decide the live Discussion</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">3 hours ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
										<div class="list d-flex align-items-center border-bottom p-3">
											<div class="">
												<span class="avatar bg-warning brround avatar-md">K</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>Meeting at 3:00 pm</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">4 hours ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
										<div class="list d-flex align-items-center border-bottom p-3">
											<div class="">
												<span class="avatar bg-success brround avatar-md">R</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>Prepare for Presentation</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">1 days ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
										<div class="list d-flex align-items-center border-bottom p-3">
											<div class="">
												<span class="avatar bg-pink brround avatar-md">MS</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>Prepare for Presentation</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">1 days ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
										<div class="list d-flex align-items-center border-bottom p-3">
											<div class="">
												<span class="avatar bg-purple brround avatar-md">L</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>Prepare for Presentation</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">45 mintues ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
										<div class="list d-flex align-items-center p-3">
											<div class="">
												<span class="avatar bg-secondary brround avatar-md">U</span>
											</div>
											<a class="wrapper w-100 ms-3" href="javascript:void(0);" >
												<p class="mb-0 d-flex ">
													<b>Prepare for Presentation</b>
												</p>
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<i class="mdi mdi-clock text-muted me-1 tx-11"></i>
														<small class="text-muted ms-auto">2 days ago</small>
														<p class="mb-0"></p>
													</div>
												</div>
											</a>
										</div>
									</div>
									<div class="tab-pane  " id="side2">
										<div class="list-group list-group-flush ">
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-3">
													<span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/12.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div>
													<strong>Madeleine</strong> Hey! there I' am available....
													<div class="small text-muted">
														3 hours ago
													</div>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-3">
													<span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/1.jpg"></span>
												</div>
												<div>
													<strong>Anthony</strong> New product Launching...
													<div class="small text-muted">
														5 hour ago
													</div>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-3">
													<span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/2.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div>
													<strong>Olivia</strong> New Schedule Realease......
													<div class="small text-muted">
														45 mintues ago
													</div>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-3">
													<span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/8.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div>
													<strong>Madeleine</strong> Hey! there I' am available....
													<div class="small text-muted">
														3 hours ago
													</div>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-3">
													<span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/11.jpg"></span>
												</div>
												<div>
													<strong>Anthony</strong> New product Launching...
													<div class="small text-muted">
														5 hour ago
													</div>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-3">
													<span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/6.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div>
													<strong>Olivia</strong> New Schedule Realease......
													<div class="small text-muted">
														45 mintues ago
													</div>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-3">
													<span class="avatar avatar-lg brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div>
													<strong>Olivia</strong> Hey! there I' am available....
													<div class="small text-muted">
														12 mintues ago
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="tab-pane  " id="side3">
										<div class="list-group list-group-flush ">
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Mozelle Belt</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/11.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Florinda Carasco</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel" ><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/10.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/2.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Zula Mclaughin</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/13.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Isidro Heide</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/12.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Mozelle Belt</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/4.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Florinda Carasco</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/7.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/2.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Zula Mclaughin</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel" ><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/14.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Isidro Heide</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" ><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/11.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Florinda Carasco</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/9.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Alina Bernier</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/15.jpg"><span class="avatar-status bg-success"></span></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Zula Mclaughin</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
											<div class="list-group-item d-flex  align-items-center">
												<div class="me-2">
													<span class="avatar avatar-md brround cover-image" data-image-src="https://php.spruko.com/nowa/nowa/assets/img/faces/4.jpg"></span>
												</div>
												<div class="">
													<div class="font-weight-semibold" data-bs-toggle="modal" data-bs-target="#chatmodel">Isidro Heide</div>
												</div>
												<div class="ms-auto">
													<a href="javascript:void(0);" class="btn btn-sm btn-outline-light btn-rounded" data-bs-toggle="modal" data-bs-target="#chatmodel"><i class="mdi mdi-message-outline"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!--/APP-SIDEBAR-RIGHT--> */}

					{<Footer />}

				</div>
				{/* <!-- End Page --> */}

				{/* <!-- BACK-TO-TOP --> */}
				<a href="#top" id="back-to-top"><i class="las la-arrow-up"></i></a>

			</body>
		)
	}
}
export default AccountSettings